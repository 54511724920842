import * as React from "react";
import moment from "moment-timezone";
import { CalendarDays } from "lucide-react";

import { CommonDestinationTimezone, cn } from "../../lib/utils";
import { Button } from "./button";
import { Input } from "./input";
import { Calendar, DateRange, SelectRangeEventHandler } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export function DatePicker({
    value: date,
    onChange: setDate,
    tabIndex,
    placeholder,
    className
}: {
    placeholder?: string;
    value?: Date,
    onChange: (date: Date | undefined) => void,
    tabIndex?: number;
    className?: string
}) {
  const [stringDate, setStringDate] = React.useState<string>("")
  const [errorMessage, setErrorMessage] = React.useState<string>("")

    React.useEffect(() => {
        let computedDate = date ? moment(date).format("MM/DD/YYYY") : "";
        if (stringDate !== computedDate) {
            setStringDate(computedDate);
        }
    }, [date]);

  const fromDate = moment().subtract(5, "years").startOf("day").toDate();
  const toDate = moment().add(5, "years").endOf("day").toDate();

  const fromYear = moment().subtract(5, "years").startOf("day").year();
  const toYear = moment().add(5, "years").endOf("day").year();

  return (
      <Popover>
          <div className="relative w-[280px]">
              <Input
                  className={className}
                  tabIndex={tabIndex}
                  type="string"
                  value={stringDate}
                  placeholder={placeholder || "MM/DD/YYYY"}
                  onChange={(e) => {
                      setStringDate(e.target.value);

                      if (e.target.value?.length !== 10) {
                          return;
                      }

                      const parsedDate = moment(e.target.value);
                      if (!parsedDate.isValid()) {
                          setErrorMessage("Invalid Date");
                          setDate(undefined);
                      } else {
                          setErrorMessage("");
                          setDate(parsedDate.toDate());
                      }
                  }}
              />
              <PopoverTrigger asChild>
                  <Button
                      variant={"outline"}
                      className={cn(
                          "font-normal absolute right-0 translate-y-[-50%] top-[50%] rounded-l-none",
                          !date && "text-muted-foreground"
                      )}
                  >
                      <CalendarDays className="w-4 h-4" />
                  </Button>
              </PopoverTrigger>
          </div>
          <PopoverContent className="w-auto p-0">
              <Calendar
                    mode="single"
                    captionLayout="dropdown-buttons"
                    fromDate={fromDate}
                    toDate={toDate}
                    fromYear={fromYear}
                    toYear={toYear}
      selected={date ? moment.tz(date, CommonDestinationTimezone).toDate(): undefined}
      onSelect={(selectedDate) => {
                      if (!selectedDate) return
                      setDate(selectedDate)

                      setStringDate(moment(selectedDate).format("MM/DD/YYYY"))
                      setErrorMessage("")
                  }}
                  defaultMonth={date ? moment(date).toDate(): undefined}
                  initialFocus
              />
          </PopoverContent>
      </Popover>
  )
}
