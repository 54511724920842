import { create } from "zustand";
import moment from "moment-timezone";
import {
  Minus as MinusIcon,
  Star as StarIcon,
  ArchiveRestore as ArchiveRestoreIcon,
} from "lucide-react";
import { z } from "zod";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DateRangePicker, DateRange } from "../../../components/ui/date_picker";
import { useToast } from "../../../components/ui/use-toast";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Skeleton } from "../../../components/ui/skeleton";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Loader } from "../../../components/ui/loader";
import { SelectInput } from "../../../components/ui/SelectInput";
import { Checkbox } from "../../../components/ui/checkbox";
import { Button } from "../../../components/ui/button";
import { Separator } from "../../../components/ui/separator";
import {
  useRunBacktest,
  CommonDestinationTimezone,
  useGetSavedConfigs,
  useUpdateConfig,
  useSaveConfig,
  AtsSavedConfig,
} from "../../../lib/api/trading";
import { Switch } from "../../../components/ui/switch";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import { handleFocus } from "../../../lib/utils";

type ConfigId = "up_trend" | "down_trend";
enum RangeSignalType {
  RSI = "RSI",
  DMI = "DMI",
  PercentR = "PercentR",
  SMA = "SMA",
}

// TODO on filter / change filter out already added studies
const studyOptions = [
  { value: RangeSignalType.PercentR, label: "Percent R" },
  { value: RangeSignalType.RSI, label: "RSI" },
  { value: RangeSignalType.DMI, label: "DMI" },
  { value: RangeSignalType.SMA, label: "SMA" },
];

enum StopTypeMode {
  FixedTicks = "FixedTicks",
  Percentage = "Percentage",
}

enum DrawdownTypeMode {
  FixedTicks = "FixedTicks",
  Points = "Points",
}

enum StopLossModifierType {
  None = "None",
  Fixed = "Fixed",
  Linear = "Linear",
}

export const backoffTypes = [
  {
    value: "always",
    label: "Always",
  },
  {
    value: "stop_loss_only",
    label: "SL Only",
  },
];

export const orderBackoffTypes = [
  {
    value: "Buy",
    label: "Buy",
  },
  {
    value: "Sell",
    label: "Sell",
  },
];

export const stopLossModifierOptions = [
  {
    value: StopLossModifierType.None,
    label: "None",
  },
  {
    value: StopLossModifierType.Fixed,
    label: "Secondary",
  },
  // {
  //   value: StopLossModifierType.Linear,
  //   label: "Linear Subtraction",
  // },
];

export const fixedPointsTypeModeOptions = [
  {
    value: DrawdownTypeMode.FixedTicks,
    label: "Fixed number of ticks",
  },
  {
    value: DrawdownTypeMode.Points,
    label: "Points",
  },
];

export const stopTypeModeOptions = [
  {
    value: StopTypeMode.FixedTicks,
    label: "Fixed number of ticks",
  },
  {
    value: StopTypeMode.Percentage,
    label: "Percentage",
  },
];

export const riskStopLossTargetPatternOptions = [
  {
    value: "any",
    label: "Any",
  },
  {
    value: "same",
    label: "Same",
  },
];

const defaultPresets = [
  {
    value: "2020-02-27T02:20:00/2022-12-09T15:55:00",
    label: "Feb 27, 2020 to Dec 09, 2022",
  },
  {
    value: "2022-09-15T00:00:00/2023-03-31T23:55:00",
    label: "Sept 15, 2022 to Mar 31st, 2023",
  },
  {
    value: "2020-05-06T06:00:00/2020-06-30T04:05:00",
    label: "May 6, 2020 to June 30, 2020",
  },
  {
    value: "2020-10-05T13:10:00/2020-10-29T13:35:00",
    label: "Oct 05, 2020 to Oct 29, 2020",
  },
  {
    value: "2021-01-05T15:30:00/2021-01-27T03:20:00",
    label: "Jan 05, 2021 to Jan 27, 2021",
  },
  {
    value: "2021-05-04T00:20:00/2021-07-23T11:10:00",
    label: "May 04, 2021 to Jul 23, 2021",
  },
  {
    value: "2021-09-15T14:00:00/2021-10-04T14:35:00",
    label: "Sep 15, 2021 to Oct 04, 2021",
  },
  {
    value: "2021-12-01T10:50:00/2022-02-24T07:40:00",
    label: "Dec 01, 2021 to Feb 24, 2022",
  },
  {
    value: "2022-03-16T01:15:00/2022-04-11T14:50:00",
    label: "Mar 16, 2022 to Apr 11, 2022",
  },
  {
    value: "2022-05-23T14:55:00/2022-06-14T01:55:00",
    label: "May 23, 2022 to Jun 14, 2022",
  },
  {
    value: "2022-09-27T08:45:00/2022-10-20T02:55:00",
    label: "Sep 27, 2022 to Oct 20, 2022",
  },
];

export const dataSets = [
  {
    value: "five-minute/es_full_latest",
    label: "ES - Full Latest",
    bounds: {
      start: "2008-01-02T06:00",
      end: "2024-02-09T16:55",
    },
  },
  {
    value: "five-minute/EsFrom2020To2022FullBacktest",
    label: "ES - 2020 - 2022",
    bounds: {
      start: "2020-02-27T02:20",
      end: "2022-12-09T15:55",
    },
  },

  {
    value: "five-minute/AAPLFromMar2022ToJan2024",
    label: "AAPL - 2022-2024",
    bounds: {
      start: "2022-03-10T08:35:00",
      end: "2024-01-05T15:55:00",
    },
  },
  {
    value: "five-minute/zn_full_latest",
    label: "ZN Full Latest",
    bounds: {
      start: "2008-01-01T19:00:00",
      end: "2024-02-09T16:55",
    },
  },
  {
    value: "five-minute/c_full_latest",
    label: "C Full Latest",
    bounds: {
      start: "2008-03-12T11:55",
      end: "2024-02-09T11:50",
    },
  },
  {
    value: "five-minute/dg_full_latest",
    label: "DG Full Latest",
    bounds: {
      start: "2009-11-13T09:45",
      end: "2024-02-09T16:05",
    },
  },
  {
    value: "five-minute/QCLCFromFeb2020ToDec2022",
    label: "Crude Oil - Feb 2020 to Dec 2022",
    bounds: {
      start: "2020-02-26T14:10",
      end: "2022-12-11T18:35",
    },
  },
  {
    value: "one-minute/EsFromJan22ToMar22",
    label: "1 min ES - Jan 2022 to Mar 2022",
    bounds: {
      start: "2022-01-02T15:00:00",
      end: "2022-03-31T23:59:00",
    },
  },
  {
    value: "one-minute/EsFromJan22ToSep23",
    label: "1 min ES - Jan 2022 to Sep 2023",
    bounds: {
      start: "2022-01-02T15:00:00",
      end: "2023-09-19T00:00:00",
    },
  },
  {
    value: "one-minute/EsFromMar23ToAug23",
    label: "1 min ES - Mar 2023 to Aug 2023",
    bounds: {
      start: "2023-03-01T00:00:00",
      end: "2023-08-31T23:59:00",
    },
  },
  {
    value: "day/raw/corn",
    label: "Day Corn",
    bounds: {
      start: "2008-12-11",
      end: "2023-07-11",
    },
  },
  {
    value: "day/raw/sp_500",
    label: "Day SP 500",
    bounds: {
      start: "2008-12-19",
      end: "2023-07-11",
    },
  },
  {
    value: "day/raw/crude_oil",
    label: "Day Crude Oil",
    bounds: {
      start: "2008-12-12",
      end: "2023-07-11",
    },
  },
];

interface RiskStopLossConfig {
  target_count: number;
  target_timeframe: number;
  target_loss_pattern: {
    type: "any" | "same";
    same: { backoff_type: "Buy" | "Sell" };
  };
  result_block_bars: number;
}

interface RiskDrawdownConfig {
  target_decline_mode: DrawdownTypeMode;
  target_decline: FixedPointsData;
  target_timeframe: number;
  result_block_bars: number;
}

interface RiskEventRulesConfig {
  target_decline_mode: DrawdownTypeMode;
  target_decline: FixedPointsData;
  target_timeframe: number;
  result_block_bars: number;
}

interface FixedPointsData {
  fixedAmount: number;
  pointsAmount: number;
}

interface FixedPercentageData {
  fixedAmount: number;
  percentAmount: number;
}

interface StopLossModifier {
  type: "None" | "Fixed" | "Linear";
  fixed: { new_amount: number };
  linear: { amount: number; min: number };
}

interface BaseStopConfig {
  stopGainMode: StopTypeMode;
  stopLossMode: StopTypeMode;
  lossData: FixedPercentageData;
  gainData: FixedPercentageData;
  stopLossModifier: StopLossModifier;
}

interface BaseBackoff {
  buy_backoff: number;
  sell_backoff: number;
}

interface DatasetConfig {
  data_set: string;
  presetStart?: Date;
  presetEnd?: Date;
  presetStartTime: string;
  presetEndTime: string;
  slicePreset: string;
}

interface StandardConfig {
  incubationPeriod: number;
  signalCountRequired: number;

  purchaseSize: number;
  convergencePeriod: number;

  backoffMode: string;
}

interface Enabled<T> {
  enabled: boolean;
  data: T;
}

interface BuySellRange {
  Buy: number;
  Sell: number;
}

interface StudyDescription {
  Type: RangeSignalType;
  BuySell: BuySellRange;
  WindowSize: number;
  MinBarCount: number;
}

interface SignalConfig {
  id: string;
  signals_ranges: Record<string, StudyDescription>;

  standardConfig: StandardConfig;
  baseStopConfig: BaseStopConfig;
  baseBackoffConfig: BaseBackoff;

  riskIndexEventRulesConfig: Enabled<RiskEventRulesConfig>;
  riskOrderEventRulesConfig: Enabled<RiskEventRulesConfig>;
  riskDrawdownConfig: Enabled<RiskDrawdownConfig>;
  riskStoplossConfig: Enabled<RiskStopLossConfig>;
}

interface TimerConfig {
  study_type: RangeSignalType;
  window_size: number;
  bar_size: string;
  marker_end: string;
  trend_threshold: number;
  up_trend_config_id: string;
  down_trend_config_id: string;
}

interface BaseState {
  tickSize: number;
  riskEquityPerTrade: number;
  TickCostInAsset: number;
  PointCostInAsset: number;
  outputIsMostRecentDateFirst: boolean;
  write_periods_to_s3: boolean;
  graph_cumulative_gain: boolean;
  default_config_id: ConfigId;
  refresh_sell_back_off_as_buy: boolean;
}

interface BearState {
  up_trend: SignalConfig;
  down_trend: SignalConfig;
  timer: TimerConfig;
  use_timer: boolean;

  datasetConfig: DatasetConfig;

  baseState: BaseState;

  private_key: string;

  updateBaseState: (changes: Partial<BaseState>) => void;

  updateConfigSignal: (
    config_id: ConfigId,
    range_type: RangeSignalType,
    changes: Partial<StudyDescription>,
  ) => void;
  add_config_signal: (config_id: ConfigId, range_type: RangeSignalType) => void;
  remove_config_signal: (
    config_id: ConfigId,
    range_type: RangeSignalType,
  ) => void;

  updateStandardConfig: (
    config_id: ConfigId,
    changes: Partial<StandardConfig>,
  ) => void;
  updateBackoffConfig: (
    config_id: ConfigId,
    changes: Partial<BaseBackoff>,
  ) => void;
  updateBaseStopConfig: (
    config_id: ConfigId,
    changes: Partial<BaseStopConfig>,
  ) => void;

  updateRiskStopLossConfig: (
    config_id: ConfigId,
    changes: Partial<RiskStopLossConfig>,
  ) => void;
  updateRiskDrawdownConfig: (
    config_id: ConfigId,
    changes: Partial<RiskDrawdownConfig>,
  ) => void;
  updateRiskOrderEventRulesConfig: (
    config_id: ConfigId,
    changes: Partial<RiskEventRulesConfig | undefined>,
  ) => void;
  updateRiskIndexEventRulesConfig: (
    config_id: ConfigId,
    changes: Partial<RiskEventRulesConfig | undefined>,
  ) => void;
  updateRiskEnabledStatus: (
    config_id: ConfigId,
    enabled: boolean,
    key:
      | "riskDrawdownConfig"
      | "riskOrderEventRulesConfig"
      | "riskIndexEventRulesConfig"
      | "riskStoplossConfig",
  ) => void;

  swapConfig: (config: Partial<BearState>) => void;

  updateTimer: (changes: Partial<TimerConfig>) => void;
  manageUseTimer: (item: boolean) => void;
  updateDatasetConfig: (changes: Partial<DatasetConfig>) => void;
  updateSlicePresetRange: (changes: Partial<DatasetConfig | undefined>) => void;
}

function generateStandardConfigForStudy(
  rangeType: RangeSignalType,
): StudyDescription {
  switch (rangeType) {
    case RangeSignalType.DMI:
      return {
        Type: RangeSignalType.DMI,
        BuySell: { Buy: 50, Sell: 80 },
        WindowSize: 9,
        MinBarCount: 2,
      };
    case RangeSignalType.PercentR:
      return {
        Type: RangeSignalType.PercentR,
        BuySell: { Buy: 99, Sell: 15 },
        WindowSize: 9,
        MinBarCount: 2,
      };
    case RangeSignalType.RSI:
      return {
        Type: RangeSignalType.RSI,
        BuySell: { Buy: 27, Sell: 81 },
        WindowSize: 9,
        MinBarCount: 2,
      };
    case RangeSignalType.SMA:
      return {
        Type: RangeSignalType.SMA,
        BuySell: { Buy: 27, Sell: 81 },
        WindowSize: 9,
        MinBarCount: 2,
      };
  }
}

function generateDefaultFiveMinute() {
  return {
    [RangeSignalType.PercentR]: generateStandardConfigForStudy(
      RangeSignalType.PercentR,
    ),
    [RangeSignalType.DMI]: generateStandardConfigForStudy(RangeSignalType.DMI),
    [RangeSignalType.RSI]: generateStandardConfigForStudy(RangeSignalType.RSI),
  };
}

function generateBaseSignalConfig(): SignalConfig {
  return {
    id: "",
    signals_ranges: generateDefaultFiveMinute(),

    standardConfig: {
      backoffMode: "stop_loss_only",
      convergencePeriod: 150,
      incubationPeriod: 23,
      purchaseSize: 1,
      signalCountRequired: 3,
    },

    baseStopConfig: {
      gainData: { percentAmount: 6, fixedAmount: 200 },
      lossData: { percentAmount: 1, fixedAmount: 110 },
      stopGainMode: StopTypeMode.Percentage,
      stopLossMode: StopTypeMode.Percentage,
      stopLossModifier: {
        type: "None",
        fixed: { new_amount: 0.5 },
        linear: { amount: 0.3, min: 0.5 },
      },
    },

    baseBackoffConfig: {
      buy_backoff: 110,
      sell_backoff: 110,
    },

    riskIndexEventRulesConfig: {
      enabled: false,
      data: {
        target_decline: { fixedAmount: 120, pointsAmount: 30 },
        target_decline_mode: DrawdownTypeMode.Points,
        target_timeframe: 5,
        result_block_bars: 5,
      },
    },
    riskOrderEventRulesConfig: {
      enabled: false,
      data: {
        target_decline: { fixedAmount: 120, pointsAmount: 30 },
        target_decline_mode: DrawdownTypeMode.Points,
        target_timeframe: 5,
        result_block_bars: 5,
      },
    },
    riskDrawdownConfig: {
      enabled: false,
      data: {
        target_decline: { fixedAmount: 800, pointsAmount: 400 },
        target_decline_mode: DrawdownTypeMode.Points,
        target_timeframe: 100,
        result_block_bars: 80,
      },
    },
    riskStoplossConfig: {
      enabled: false,
      data: {
        target_count: 12,
        result_block_bars: 60,
        target_timeframe: 50,
        target_loss_pattern: { type: "same", same: { backoff_type: "Sell" } },
      },
    },
  };
}

const useBearStore = create<BearState>((set) => ({
  baseState: {
    tickSize: 0.25,
    riskEquityPerTrade: 7.5,
    TickCostInAsset: 12.5,
    PointCostInAsset: 50,
    outputIsMostRecentDateFirst: true,
    graph_cumulative_gain: false,
    write_periods_to_s3: false,
    default_config_id: "up_trend",
    refresh_sell_back_off_as_buy: true,
  },

  private_key: "",

  timer: {
    bar_size: "1d",
    down_trend_config_id: "down_trend",
    up_trend_config_id: "up_trend",
    marker_end: "15:55",
    study_type: RangeSignalType.SMA,
    trend_threshold: 100,
    window_size: 9,
  },
  use_timer: false,

  up_trend: {
    ...generateBaseSignalConfig(),
    id: "up_trend",
  },
  down_trend: {
    ...generateBaseSignalConfig(),
    id: "down_trend",
  },
  datasetConfig: {
    // TODO ideally we limit the presets to only the data sets that support it
    // And also add a calendar to choose the dates
    data_set: dataSets[0].value,
    presetStart: undefined,
    presetEnd: undefined,
    presetStartTime: "00:00",
    presetEndTime: "00:00",
    slicePreset: "",
  },

  swapConfig: (config: Partial<BearState>) =>
    set((state) => ({
      ...state,
      ...config,
    })),
  updateTimer: (newConfigChanges) =>
    set((state) => ({
      timer: { ...state.timer, ...newConfigChanges },
    })),
  manageUseTimer: (status) =>
    set((state) => ({
      use_timer: status,
    })),

  updateDatasetConfig: (newConfigChanges) =>
    set((state) => ({
      datasetConfig: { ...state.datasetConfig, ...newConfigChanges },
    })),
  updateSlicePresetRange: (changes) =>
    set((state) => ({
      datasetConfig: {
        ...state.datasetConfig,
        ...(!changes
          ? { presetStart: undefined, presetEnd: undefined }
          : changes),
      },
    })),

  updateBaseState: (changes) =>
    set((state) => ({
      baseState: { ...state.baseState, ...changes },
    })),

  updateBaseStopConfig: (config_id: ConfigId, newConfigChanges) =>
    set((state) => ({
      [config_id]: {
        ...state[config_id],
        baseStopConfig: {
          ...state[config_id].baseStopConfig,
          ...newConfigChanges,
        },
      },
    })),
  updateBackoffConfig: (config_id: ConfigId, newConfigChanges) =>
    set((state) => ({
      [config_id]: {
        ...state[config_id],
        baseBackoffConfig: {
          ...state[config_id].baseBackoffConfig,
          ...newConfigChanges,
        },
      },
    })),
  updateStandardConfig: (config_id: ConfigId, newConfigChanges) =>
    set((state) => ({
      [config_id]: {
        ...state[config_id],
        standardConfig: {
          ...state[config_id].standardConfig,
          ...newConfigChanges,
        },
      },
    })),
  updateRiskEnabledStatus: (
    config_id: ConfigId,
    enabled: boolean,
    key:
      | "riskDrawdownConfig"
      | "riskOrderEventRulesConfig"
      | "riskIndexEventRulesConfig"
      | "riskStoplossConfig",
  ) =>
    set((state) => ({
      [config_id]: {
        ...state[config_id],
        [key]: {
          enabled,
          data: state[config_id][key].data,
        },
      },
    })),
  updateRiskStopLossConfig: (config_id: ConfigId, newConfigChanges) =>
    set((state) => ({
      [config_id]: {
        ...state[config_id],
        riskStoplossConfig: {
          enabled: state[config_id].riskStoplossConfig.enabled,
          data: {
            ...state[config_id].riskStoplossConfig.data,
            ...newConfigChanges,
          },
        },
      },
    })),
  updateRiskDrawdownConfig: (config_id: ConfigId, newConfigChanges) =>
    set((state) => ({
      [config_id]: {
        ...state[config_id],
        riskDrawdownConfig: {
          enabled: state[config_id].riskDrawdownConfig.enabled,
          data: {
            ...state[config_id].riskDrawdownConfig.data,
            ...newConfigChanges,
          },
        },
      },
    })),
  updateRiskOrderEventRulesConfig: (config_id: ConfigId, newConfigChanges) =>
    set((state) => ({
      [config_id]: {
        ...state[config_id],
        riskOrderEventRulesConfig: {
          enabled: state[config_id].riskOrderEventRulesConfig.enabled,
          data: {
            ...state[config_id].riskOrderEventRulesConfig.data,
            ...newConfigChanges,
          },
        },
      },
    })),
  updateRiskIndexEventRulesConfig: (config_id: ConfigId, newConfigChanges) =>
    set((state) => ({
      [config_id]: {
        ...state[config_id],
        riskIndexEventRulesConfig: {
          enabled: state[config_id].riskIndexEventRulesConfig.enabled,
          data: {
            ...state[config_id].riskIndexEventRulesConfig.data,
            ...newConfigChanges,
          },
        },
      },
    })),

  add_config_signal: (config_id, range_type) =>
    set((state) => ({
      [config_id]: {
        ...state[config_id],
        signals_ranges: {
          ...state[config_id].signals_ranges,
          [range_type]: {
            ...generateStandardConfigForStudy(range_type),
          },
        },
      },
    })),

  remove_config_signal: (config_id, range_type) =>
    set((state) => ({
      [config_id]: {
        ...state[config_id],
        signals_ranges: {
          ...state[config_id].signals_ranges,
          [range_type]: null,
        },
      },
    })),

  updateConfigSignal: (config_id, range_type, newConfigChanges) =>
    set((state) => ({
      [config_id]: {
        ...state[config_id],
        signals_ranges: {
          ...state[config_id].signals_ranges,
          [range_type]: {
            ...state[config_id].signals_ranges[range_type],
            ...newConfigChanges,
          },
        },
      },
    })),
}));

function getFixedPercentageData(
  mode: StopTypeMode,
  data: FixedPercentageData,
  newItem: number,
): FixedPercentageData {
  return {
    fixedAmount: mode === StopTypeMode.FixedTicks ? newItem : data.fixedAmount,
    percentAmount:
      mode === StopTypeMode.Percentage ? newItem : data.percentAmount,
  };
}

function getFixedPointsData(
  mode: DrawdownTypeMode,
  data: FixedPointsData,
  newItem: number,
): FixedPointsData {
  return {
    fixedAmount:
      mode === DrawdownTypeMode.FixedTicks ? newItem : data.fixedAmount,
    pointsAmount:
      mode === DrawdownTypeMode.Points ? newItem : data.pointsAmount,
  };
}

function SavedConfigs({}) {
  const { toast } = useToast();
  const listSavedConfigsResult = useGetSavedConfigs();
  const updateConfig = useUpdateConfig();
  const saveConfig = useSaveConfig();
  const [isUpdating, setIsUpdating] = useState(false);
  const [newConfigName, setNewConfigName] = useState("");
  const wholeConfig = useBearStore();
  const swapConfig = useBearStore((state) => state.swapConfig);

  if ([listSavedConfigsResult].some((result) => result.isLoading)) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  if (
    [listSavedConfigsResult].some(
      (result) => result.error || !result?.data?.success,
    )
  ) {
    return (
      <div className="flex flex-col space-y-2">
        Failed to fetch configs. Please refresh and try again
      </div>
    );
  }

  const configs = listSavedConfigsResult.data?.data || [];

  const saveCurrentConfig = async () => {
    setIsUpdating(true);
    const result = await saveConfig({
      is_starred: false,
      name: newConfigName,
      full_config: convertToApiBody(wholeConfig),
    });
    setIsUpdating(false);
    if (!result.success) {
      toast({
        title: "Failed to save config",
        description: "",
      });
    } else {
      setNewConfigName("");
      toast({
        title: "Saved",
        description: "",
      });
    }
  };

  const updateStarStatus = async (id: string, is_starred: boolean) => {
    setIsUpdating(true);
    await updateConfig(id, { is_starred });
    setIsUpdating(false);
  };

  const restoreConfig = async (id: string) => {
    const config = configs.find((config: AtsSavedConfig) => config.id === id);
    if (!config) return;

    const bearConfig = convertApiBodyToBearConfig(config.full_config) as any;
    swapConfig(bearConfig);

    toast({
      title: "Loaded",
      description: "",
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row sm:grid-cols-3 gap-4">
        <Label className="" htmlFor="newConfigName">
          Name:
        </Label>
        <Input
          id="newConfigName"
          value={newConfigName}
          onChange={(event) => setNewConfigName(event.target.value)}
        />

        <Button type="button" className="" onClick={() => saveCurrentConfig()}>
          Save Current Config
        </Button>
      </div>
      <div className="flex flex-col md:grid md:grid-cols-3 lg:grid-cols-3 w-full gap-4">
        <>
          <span className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
            Name
          </span>
          <span className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
            Favorite
          </span>
          <span className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
            Load
          </span>
        </>
      </div>

      <>
        {configs.map((config: AtsSavedConfig) => (
          <div
            className="flex flex-col md:grid md:grid-cols-3 lg:grid-cols-3 w-full gap-4"
            key={`Config:${config.id}`}
          >
            <span>{config.name}</span>
            <span>
              {isUpdating ? (
                <Loader />
              ) : config.is_starred ? (
                <Button
                  type="button"
                  variant="ghost"
                  className=""
                  onClick={() => updateStarStatus(config.id, false)}
                >
                  <StarIcon className="fill-yellow-300" />
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="ghost"
                  className=""
                  onClick={() => updateStarStatus(config.id, true)}
                >
                  <StarIcon />
                </Button>
              )}
            </span>
            <span>
              {isUpdating ? (
                <Loader />
              ) : (
                <Button
                  type="button"
                  variant="ghost"
                  className=""
                  onClick={() => restoreConfig(config.id)}
                >
                  <ArchiveRestoreIcon />
                </Button>
              )}
            </span>
          </div>
        ))}
      </>
    </div>
  );
}

function SignalConfigView({ configId }: { configId: ConfigId }) {
  return (
    <div className="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-2 w-full gap-4">
      <div className="flex flex-col">
        <StudiesRanges configId={configId} />
        <div className="flex flex-col md:grid md:grid-cols-2 border p-2 my-2 gap-2 max-w-md">
          <BaseStopConfigForm configId={configId} />
          <StopGainForm configId={configId} />
          <StopLossForm configId={configId} />
        </div>
        <OtherBaseConfig configId={configId} />
      </div>
      <div className="flex flex-col gap-2">
        <BaseConfig configId={configId} />
        <RiskDrawdownConfigForm
          storeKey="riskIndexEventRulesConfig"
          updateKey="updateRiskIndexEventRulesConfig"
          name="Risk Index"
          configId={configId}
        />
        {/* <RiskDrawdownConfigForm storeKey="riskDrawdownConfig" updateKey="updateRiskDrawdownConfig" name="Risk Drawdown" configId={configId} /> */}
        {/* <RiskDrawdownConfigForm storeKey="riskOrderEventRulesConfig" updateKey="updateRiskOrderEventRulesConfig" name="Risk Order" configId={configId} /> */}
        {/* <RiskStopLossConfigForm configId={configId} /> */}
      </div>
    </div>
  );
}

export const BacktestPage = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const fetchBacktestResult = useRunBacktest();
  const [isSaving, setIsSaving] = useState(false);
  const [apiResponse, setApiResponse] = useState<null | any>(null);
  const wholeConfig = useBearStore();
  const baseState = useBearStore((state) => state.baseState);
  const updateBase = useBearStore((state) => state.updateBaseState);

  async function onSubmit(values: BearState) {
    if (isSaving) return true;
    setIsSaving(true);

    const apiBody = convertToApiBody(values);
    const response = await fetchBacktestResult(apiBody);

    setApiResponse(response);

    if (!response.success) {
      toast({
        title: "Failed to generate analysis",
        description: "",
      });
    }

    setIsSaving(false);
  }
  return (
    <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
      <form
        onSubmit={(e) => {
          if (e?.preventDefault) e.preventDefault();
          return onSubmit(wholeConfig);
        }}
      >
        <div className="grid grid-cols-[5fr_2fr] gap-4">
          <Tabs defaultValue="up_trend">
            <TabsList>
              <TabsTrigger value="saved_configs">Saved Configs</TabsTrigger>
              <TabsTrigger value="timer">Config Manager</TabsTrigger>
              <TabsTrigger value="up_trend">Up Trend</TabsTrigger>
              {wholeConfig.use_timer && (
                <TabsTrigger value="down_trend">Down Trend</TabsTrigger>
              )}
              {apiResponse?.success && !!apiResponse.data.cumulative_image && (
                <TabsTrigger value="graphs">Graphs</TabsTrigger>
              )}
            </TabsList>
            <TabsContent value="saved_configs">
              <SavedConfigs />
            </TabsContent>
            <TabsContent value="timer">
              <ConfigManager />
            </TabsContent>
            <TabsContent value="up_trend">
              <SignalConfigView configId="up_trend" />
            </TabsContent>
            {wholeConfig.use_timer && (
              <TabsContent value="down_trend">
                <SignalConfigView configId="down_trend" />
              </TabsContent>
            )}
            {apiResponse?.success && !!apiResponse.data.cumulative_image && (
              <TabsContent className="h-full" value="graphs">
                <img
                  src={`data:image/png;base64,${(apiResponse.data || {}).cumulative_image}`}
                />
              </TabsContent>
            )}
          </Tabs>
          <div className="flex flex-col gap-2 my-4">
            <BearCheckbox
              name="write_periods_to_s3"
              label="Generate CSV?"
              checked={baseState.write_periods_to_s3}
              onCheckedChange={(item) =>
                updateBase({ write_periods_to_s3: item })
              }
            />
            <BearCheckbox
              name="graph_cumulative_gain"
              label="Graph Cumulative Gain"
              checked={baseState.graph_cumulative_gain}
              onCheckedChange={(item) =>
                updateBase({ graph_cumulative_gain: item })
              }
            />
            <Button type="submit" className="my-2" disabled={isSaving}>
              {isSaving ? <Loader /> : "Submit"}
            </Button>
            {apiResponse?.success && (
              <ResultsTable data={apiResponse.data || {}} />
            )}
          </div>
        </div>
      </form>
    </section>
  );
};

export default BacktestPage;

function ConfigManager({}) {
  const use_timer = useBearStore((state) => state.use_timer);
  const timer = useBearStore((state) => state.timer);
  const updateTimer = useBearStore((state) => state.updateTimer);
  const manageUseTimer = useBearStore((state) => state.manageUseTimer);
  const updateBase = useBearStore((state) => state.updateBaseState);
  const default_config_id = useBearStore(
    (state) => state.baseState.default_config_id,
  );
  const refresh_sell_back_off_as_buy = useBearStore(
    (state) => state.baseState.refresh_sell_back_off_as_buy,
  );

  return (
    <div className="" id="timer">
      <div className="grid grid-cols-2 gap-2 justify-center items-center">
        <BearSelectInput
          name="changeStudy"
          label="Change Study"
          value={timer.study_type}
          onValueChange={(item: any) => updateTimer({ study_type: item })}
          options={studyOptions}
        />

        <BearSelectInput
          name="defaultStarting Config"
          label="Default Starting config"
          value={default_config_id}
          onValueChange={(item: any) => updateBase({ default_config_id: item })}
          options={[
            { value: "up_trend", label: "Up Trend" },
            { value: "down_trend", label: "Down Trend" },
          ]}
        />

        <BearIntInput
          min="2"
          step="1"
          name={`timer_window_size`}
          required
          label="Window"
          value={timer.window_size}
          onChange={(item: any) => updateTimer({ window_size: item })}
        />

        <BearIntInput
          min="1"
          step="1"
          name={`timer_trend_thresold`}
          required
          label="Threshold"
          value={timer.trend_threshold}
          onChange={(item: any) => updateTimer({ trend_threshold: item })}
        />

        <p className="font-bold grid grid-cols-2">
          <span>Enable Down Config</span>
          <Switch
            className=""
            checked={use_timer}
            onCheckedChange={(enabled) => manageUseTimer(enabled)}
          />
        </p>

        <p className="font-bold grid grid-cols-2">
          <span>Refresh Sell Backoff as Buy</span>
          <Switch
            className=""
            checked={refresh_sell_back_off_as_buy}
            onCheckedChange={(enabled) =>
              updateBase({ refresh_sell_back_off_as_buy: enabled })
            }
          />
        </p>
      </div>
    </div>
  );
}

function StudiesRanges({ configId }: { configId: ConfigId }) {
  const signalRanges = useBearStore((state) => state[configId].signals_ranges);

  const addRange = useBearStore((state) => state.add_config_signal);
  const removeRange = useBearStore((state) => state.remove_config_signal);
  const updateConfig = useBearStore((state) => state.updateConfigSignal);

  const ranges = Object.values(signalRanges).filter(
    (item: any) => !!item,
  ) as StudyDescription[];
  ranges.sort((a, b) => a.Type.localeCompare(b.Type));

  return (
    <div className="" id="studies_buy_sell">
      <BearSelectInput
        name="addStudy"
        label="Add Study"
        value={""}
        onValueChange={(item: any) => addRange(configId, item)}
        options={studyOptions.filter(
          (item) => !ranges.map((range) => range.Type).includes(item.value),
        )}
      />
      <div className="grid grid-cols-[auto_1fr_1fr_1fr_1fr] gap-2 justify-center items-center">
        {ranges.map((range: StudyDescription) => (
          <>
            <span></span>
            <span>Buy</span>
            <span>Sell</span>
            <span>Window</span>
            <span></span>

            <span>{range.Type}</span>
            <BearIntInput
              min="0"
              max="100"
              step="1"
              name={`buy_${range.Type}`}
              required
              value={range.BuySell.Buy}
              onChange={(item) =>
                updateConfig(configId, range.Type, {
                  BuySell: { Buy: item, Sell: range.BuySell.Sell },
                })
              }
            />
            <BearIntInput
              min="0"
              max="100"
              step="1"
              name={`sell_${range.Type}`}
              required
              value={range.BuySell.Sell}
              onChange={(item) =>
                updateConfig(configId, range.Type, {
                  BuySell: { Buy: range.BuySell.Buy, Sell: item },
                })
              }
            />
            <BearIntInput
              min="2"
              step="1"
              name={`window_${range.Type}`}
              required
              value={range.WindowSize}
              onChange={(item) =>
                updateConfig(configId, range.Type, { WindowSize: item })
              }
            />
            <Button
              type="button"
              className=""
              onClick={() => removeRange(configId, range.Type)}
            >
              <MinusIcon />
            </Button>
          </>
        ))}
      </div>
    </div>
  );
}

function RiskStopLossConfigForm({ configId }: { configId: ConfigId }) {
  const storeKey = "riskStoplossConfig";
  const enabledConfig = useBearStore((state) => state[configId][storeKey]);
  const updateConfig = useBearStore((state) => state.updateRiskStopLossConfig);
  const onEnableUpdateChange = useBearStore(
    (state) => state.updateRiskEnabledStatus,
  );
  const name = "Risk Stoploss";

  if (!enabledConfig.enabled) {
    return (
      <div>
        <p className="font-bold grid grid-cols-2">
          {name}
          <Switch
            className=""
            checked={enabledConfig.enabled}
            onCheckedChange={(enabled) =>
              onEnableUpdateChange(configId, enabled, storeKey)
            }
          />
        </p>
      </div>
    );
  }

  const config = enabledConfig.data;

  return (
    <div>
      <p className="font-bold grid grid-cols-2">
        {name}
        <Switch
          className=""
          checked={enabledConfig.enabled}
          onCheckedChange={(enabled) =>
            onEnableUpdateChange(configId, enabled, storeKey)
          }
        />
      </p>
      <div className="flex flex-col md:grid md:grid-cols-2 border p-2 my-2 gap-2 max-w-md mt-2">
        <BearIntInput
          min="0"
          step="1"
          max="100"
          required
          value={config.target_count}
          onChange={(item) => updateConfig(configId, { target_count: item })}
          label="Stop Count"
          name="riskStopLossConfigTargetCount"
        />
        <BearIntInput
          min="0"
          step="1"
          required
          value={config.target_timeframe}
          onChange={(item) =>
            updateConfig(configId, { target_timeframe: item })
          }
          label="Stop Bars Window"
          name="riskStoplossTargetTimeframe"
        />
        <BearIntInput
          min="0"
          step="1"
          required
          value={config.result_block_bars}
          onChange={(item) =>
            updateConfig(configId, { result_block_bars: item })
          }
          label="Stop Bars Backoff"
          name="riskStopLossBlockBars"
        />
        <BearSelectInput
          name="riskStopLossPattern"
          label="Stop Pattern"
          value={config.target_loss_pattern.type}
          onValueChange={(item: string) =>
            updateConfig(configId, {
              target_loss_pattern: {
                ...config.target_loss_pattern,
                type: item as any,
              },
            })
          }
          options={riskStopLossTargetPatternOptions}
        />
        {config.target_loss_pattern.type === "same" && (
          <BearSelectInput
            name="riskStopLossPatternSameType"
            label="Backoff Type"
            value={config.target_loss_pattern.same.backoff_type}
            onValueChange={(item: string) =>
              updateConfig(configId, {
                target_loss_pattern: {
                  ...config.target_loss_pattern,
                  same: { backoff_type: item as any },
                },
              })
            }
            options={orderBackoffTypes}
          />
        )}
      </div>
    </div>
  );
}

function RiskDrawdownConfigForm({
  configId,
  name,
  storeKey,
  updateKey,
}: {
  configId: ConfigId;
  name: string;
  storeKey:
    | "riskDrawdownConfig"
    | "riskOrderEventRulesConfig"
    | "riskIndexEventRulesConfig";
  updateKey:
    | "updateRiskDrawdownConfig"
    | "updateRiskIndexEventRulesConfig"
    | "updateRiskOrderEventRulesConfig";
}) {
  const enabledConfig = useBearStore((state) => state[configId][storeKey]);
  const updateConfig = useBearStore((state) => state[updateKey]);
  const onEnableUpdateChange = useBearStore(
    (state) => state.updateRiskEnabledStatus,
  );

  if (!enabledConfig.enabled) {
    return (
      <div>
        <p className="font-bold grid grid-cols-2">
          {name}
          <Switch
            className=""
            checked={enabledConfig.enabled}
            onCheckedChange={(enabled) =>
              onEnableUpdateChange(configId, enabled, storeKey)
            }
          />
        </p>
      </div>
    );
  }

  const config = enabledConfig.data;

  return (
    <div>
      <p className="font-bold grid grid-cols-2">
        {name}
        <Switch
          className=""
          checked={enabledConfig.enabled}
          onCheckedChange={(enabled) =>
            onEnableUpdateChange(configId, enabled, storeKey)
          }
        />
      </p>
      <div className="flex flex-col md:grid md:grid-cols-2 border p-2 my-2 gap-2 max-w-md mt-2">
        <BearFloatInput
          min="0"
          step={
            config.target_decline_mode === DrawdownTypeMode.Points ? "0.1" : "1"
          }
          required
          value={
            config.target_decline_mode === DrawdownTypeMode.Points
              ? config.target_decline.pointsAmount
              : config.target_decline.fixedAmount
          }
          onChange={(item) =>
            updateConfig(configId, {
              target_decline: getFixedPointsData(
                config.target_decline_mode,
                config.target_decline,
                item,
              ),
            })
          }
          label="Target Decline"
          name="riskDrawdownTargetDecline"
        />
        <BearSelectInput
          name="riskDrawdownDeclineMode"
          label="Drawdown Decline Type"
          value={config.target_decline_mode}
          onValueChange={(item: string) =>
            updateConfig(configId, { target_decline_mode: item as any })
          }
          options={fixedPointsTypeModeOptions}
        />
        <BearIntInput
          min="0"
          step="1"
          required
          value={config.target_timeframe}
          onChange={(item) =>
            updateConfig(configId, { target_timeframe: item })
          }
          label="Drawdown Bars Window"
          name="riskDrawdownTargetTimeframe"
        />
        <BearIntInput
          min="0"
          step="1"
          required
          value={config.result_block_bars}
          onChange={(item) =>
            updateConfig(configId, { result_block_bars: item })
          }
          label="Drawdown Bars Backoff"
          name="riskDrawdownBlockBars"
        />
      </div>
    </div>
  );
}

function BaseConfig({ configId }: { configId: ConfigId }) {
  return (
    <>
      <div className="flex flex-col md:grid md:grid-cols-2 gap-2 my-2 max-w-md">
        <DataSetSelectInput />
        <DataSetSliceInput />
      </div>
    </>
  );
}

function OtherBaseConfig({ configId }: { configId: ConfigId }) {
  const standardConfig = useBearStore(
    (state) => state[configId].standardConfig,
  );
  const backoffConfig = useBearStore(
    (state) => state[configId].baseBackoffConfig,
  );
  const signal_ranges = useBearStore((state) => state[configId].signals_ranges);
  const updateConfig = useBearStore((state) => state.updateStandardConfig);
  const updateBackoffConfig = useBearStore(
    (state) => state.updateBackoffConfig,
  );
  const baseState = useBearStore((state) => state.baseState);
  const updateBaseState = useBearStore((state) => state.updateBaseState);

  const signal_range_count =
    Object.values(signal_ranges).filter((item) => !!item).length || 1;

  return (
    <>
      <div className="flex flex-col md:grid md:grid-cols-2 gap-2 my-2 max-w-md">
        <BearIntInput
          min="0"
          step="1"
          name="IncubationPeriod"
          label="Incubation Period"
          required
          value={standardConfig.incubationPeriod}
          onChange={(item) =>
            updateConfig(configId, { incubationPeriod: item })
          }
        />
        <BearIntInput
          min="1"
          step="1"
          max={`${signal_range_count}`}
          name="SignalCountRequired"
          label="Signal Count Required:"
          required
          value={standardConfig.signalCountRequired}
          onChange={(item) =>
            updateConfig(configId, { signalCountRequired: item })
          }
        />

        <BearIntInput
          min="1"
          step="1"
          name="BuyBackoffPeriod"
          label="Buy Backoff Period"
          required
          value={backoffConfig.buy_backoff}
          onChange={(item) =>
            updateBackoffConfig(configId, { buy_backoff: item })
          }
        />
        <BearIntInput
          min="1"
          step="1"
          name="SellBackoffPeriod"
          label="Sell Backoff Period"
          required
          value={backoffConfig.sell_backoff}
          onChange={(item) =>
            updateBackoffConfig(configId, { sell_backoff: item })
          }
        />
      </div>
      <div className="flex flex-col md:grid md:grid-cols-2 border p-2 my-2 gap-2 max-w-md">
        <BearSelectInput
          name="BackoffType"
          label="Backoff Type"
          value={standardConfig.backoffMode}
          onValueChange={(item) =>
            updateConfig(configId, { backoffMode: item })
          }
          options={backoffTypes}
        />
        <BearFloatInput
          min="0"
          max="100"
          step="0.1"
          name="RiskEquityPerTrade"
          label="Risk Equity Per Trade"
          required
          value={baseState.riskEquityPerTrade}
          onChange={(item) => updateBaseState({ riskEquityPerTrade: item })}
        />
      </div>
    </>
  );
}

function parseResultValue(key: string, value: any) {
  if (
    typeof value === "string" &&
    value.includes(".") &&
    key !== "stored_periods_url"
  ) {
    let amount = parseFloat(value);
    if (["annualized", "roi"].includes(key)) amount = amount * 100;
    return amount.toFixed(2);
  }
  return value;
}

function ResultsTable({ data }: any) {
  const gains = [
    { label: "Annualized", id: "annualized" },
    { label: "ROI", id: "roi" },
    { label: "Settled Balance", id: "settled_balance" },
    { label: "Cumulative Gain", id: "cumulative_gain" },
  ];

  const analysis = [
    { label: "Max Draw Down", id: "max_draw_down_per_trade" },
    { label: "Stop Gains", id: "position_stop_gains" },
    { label: "Stop Losss", id: "position_stop_losses" },
    { label: "Reverse To Long Position", id: "reverse_to_long_count" },
    { label: "Reverse To Short Position", id: "reverse_to_short_count" },
    { label: "Longs", id: "long_count" },
    { label: "Shorts", id: "short_count" },
  ];

  const general = [
    { label: "# of Days Backtested", id: "number_of_days_backtested" },
    { label: "Equity per BU", id: "equity_per_bu" },
  ];

  return (
    <div className="flex flex-col h-full">
      <div className="border rounded-xl grid grid-cols-[1fr_auto_1fr] p-4 gap-4  mx-auto justify-center items-center">
        {gains.map((item) => (
          <ResultCell data={data} item={item} />
        ))}
        {analysis.map((item) => (
          <ResultCell data={data} item={item} />
        ))}
        {general.map((item) => (
          <ResultCell data={data} item={item} />
        ))}
      </div>
      {data.stored_periods_url && (
        <a
          href={data.stored_periods_url}
          target="_blank"
          className="underline underline-offset-1 decoration-sky-500"
        >
          S3 link (presigned)
        </a>
      )}
    </div>
  );
}

function ResultCell({ item, data }: any) {
  return (
    <>
      <Label className="" htmlFor={`Result:${item.id}`}>
        {item.label}
      </Label>
      <Separator orientation="vertical" />

      <span id={`Result:${item.id}`} className="">
        {parseResultValue(item.id, data[item.id])}
      </span>
    </>
  );
}

function BaseStopConfigForm({ configId }: { configId: ConfigId }) {
  const config = useBearStore((state) => state[configId].baseStopConfig);
  const updateConfig = useBearStore((state) => state.updateBaseStopConfig);

  return (
    <>
      <BearSelectInput
        name="stopLossType"
        label="Stop Loss Type"
        value={config.stopLossMode}
        onValueChange={(item) =>
          updateConfig(configId, { stopLossMode: item as any })
        }
        options={stopTypeModeOptions}
      />

      <BearSelectInput
        name="stopLossModifier"
        label="Stop Loss Modifier"
        value={config.stopLossModifier.type}
        onValueChange={(item) =>
          updateConfig(configId, {
            stopLossModifier: { ...config.stopLossModifier, type: item as any },
          })
        }
        options={stopLossModifierOptions}
      />
    </>
  );
}

function StopLossForm({ configId }: { configId: ConfigId }) {
  const config = useBearStore((state) => state[configId].baseStopConfig);
  const updateConfig = useBearStore((state) => state.updateBaseStopConfig);

  return (
    <>
      <BearFloatInput
        min="0"
        step={config.stopLossMode === StopTypeMode.Percentage ? "0.1" : "1"}
        max={
          config.stopLossMode === StopTypeMode.Percentage ? "100" : undefined
        }
        name="StopLossPercent"
        label={
          config.stopLossMode === StopTypeMode.Percentage
            ? "Stop Loss Percent"
            : "Stop Loss ticks"
        }
        required
        value={
          config.stopLossMode === StopTypeMode.Percentage
            ? config.lossData.percentAmount
            : config.lossData.fixedAmount
        }
        onChange={(item) =>
          updateConfig(configId, {
            lossData: getFixedPercentageData(
              config.stopLossMode,
              config.lossData,
              item,
            ),
          })
        }
      />
      {config.stopLossModifier.type === StopLossModifierType.Fixed && (
        <BearFloatInput
          min="0"
          step="0.1"
          name="StopLossFixedAmount"
          label="Secondary Stop Loss"
          required
          value={config.stopLossModifier.fixed.new_amount}
          onChange={(item) =>
            updateConfig(configId, {
              stopLossModifier: {
                ...config.stopLossModifier,
                fixed: { new_amount: item },
              },
            })
          }
        />
      )}
      {config.stopLossModifier.type === StopLossModifierType.Linear && (
        <>
          <BearFloatInput
            min="0"
            step="1"
            name="StopLossLinearAmount"
            label="Stop Loss Tick Dropoff amount"
            required
            value={config.stopLossModifier.linear.amount}
            onChange={(item) =>
              updateConfig(configId, {
                stopLossModifier: {
                  ...config.stopLossModifier,
                  linear: {
                    amount: item,
                    min: config.stopLossModifier.linear.min,
                  },
                },
              })
            }
          />
          <BearFloatInput
            min="1"
            step="1"
            name="StopLossLinearMinAmount"
            label="Stop Loss Tick minimum"
            required
            value={config.stopLossModifier.linear.min}
            onChange={(item) =>
              updateConfig(configId, {
                stopLossModifier: {
                  ...config.stopLossModifier,
                  linear: {
                    amount: config.stopLossModifier.linear.amount,
                    min: item,
                  },
                },
              })
            }
          />
        </>
      )}
    </>
  );
}

function StopGainForm({ configId }: { configId: ConfigId }) {
  const config = useBearStore((state) => state[configId].baseStopConfig);
  const updateConfig = useBearStore((state) => state.updateBaseStopConfig);

  return (
    <>
      <BearSelectInput
        name="stopGainType"
        label="Stop Gain Type"
        value={config.stopGainMode}
        onValueChange={(item) =>
          updateConfig(configId, { stopGainMode: item as any })
        }
        options={stopTypeModeOptions}
      />
      <BearFloatInput
        min="0"
        step={config.stopGainMode === StopTypeMode.Percentage ? "0.1" : "1"}
        max={
          config.stopGainMode === StopTypeMode.Percentage ? "100" : undefined
        }
        name="StopGainPercent"
        label={
          config.stopGainMode === StopTypeMode.Percentage
            ? "Stop Gain Percent"
            : "Stop Gain ticks"
        }
        required
        value={
          config.stopGainMode === StopTypeMode.Percentage
            ? config.gainData.percentAmount
            : config.gainData.fixedAmount
        }
        onChange={(item) =>
          updateConfig(configId, {
            gainData: getFixedPercentageData(
              config.stopGainMode,
              config.gainData,
              item,
            ),
          })
        }
      />
    </>
  );
}

function getNearestDataset({ dataSet }: { dataSet: string }): string {
  const currentDataSetOption = dataSets.find(
    (setOption) => setOption.value === dataSet,
  );

  if (!currentDataSetOption) return "";

  return dataSet;
}

function generateDefaultPresetsForSlice(dataSet?: {
  value: string;
  label: string;
  bounds: { start: string; end: string };
}) {
  if (!dataSet) return [{ value: "", label: "None" }];

  return [
    { value: "", label: "None" },
    ...defaultPresets.filter((preset) => {
      const [start, end] = preset.value.split("/");
      const presetStart = moment.tz(start, CommonDestinationTimezone);
      const presetEnd = moment.tz(end, CommonDestinationTimezone);

      const dataSetStart = moment.tz(
        dataSet.bounds.start,
        CommonDestinationTimezone,
      );
      const dataSetEnd = moment.tz(
        dataSet.bounds.end,
        CommonDestinationTimezone,
      );

      return (
        presetStart.isSameOrAfter(dataSetStart) &&
        presetEnd.isSameOrBefore(dataSetEnd)
      );
    }),
  ];
}

function getOrClearSlice({
  dataSet,
  currentSlice,
}: {
  dataSet: string;
  currentSlice: string;
}): string {
  const allowedSlices = generateDefaultPresetsForSlice(
    dataSets.find((item) => item.value === dataSet),
  );
  if (!allowedSlices.find((slice) => slice.value === currentSlice)) {
    return "";
  }
  return currentSlice;
}

function DataSetSelectInput() {
  const datasetConfig = useBearStore((state) => state.datasetConfig);
  const updateDatasetConfig = useBearStore(
    (state) => state.updateDatasetConfig,
  );

  return (
    <BearSelectInput
      name="dataset"
      label="Data Set"
      value={datasetConfig.data_set}
      onValueChange={(item) => {
        const data_set = item;
        updateDatasetConfig({
          data_set,
        });

        setTimeout(() => {
          updateDatasetConfig({
            data_set,
            slicePreset: getOrClearSlice({
              dataSet: data_set,
              currentSlice: datasetConfig.slicePreset,
            }),
            presetStart: undefined,
            presetEnd: undefined,
            presetStartTime: "00:00",
            presetEndTime: "00:00",
          });
        }, 0);
      }}
      options={dataSets}
    />
  );
}

function DataSetSliceInput() {
  const datasetConfig = useBearStore((state) => state.datasetConfig);
  const updateDatasetConfig = useBearStore(
    (state) => state.updateDatasetConfig,
  );

  return (
    <>
      <BearSelectInput
        name="datasetPreset"
        label="Data Slice"
        value={datasetConfig.slicePreset}
        onValueChange={(item) => {
          const foundPreset = defaultPresets.find(
            (preset) => preset.value === item,
          );

          const presetStartTime = foundPreset
            ? foundPreset.value.split("/")[0].split("T")[1].substring(0, 5)
            : "00:00";
          const presetEndTime = foundPreset
            ? foundPreset.value.split("/")[1].split("T")[1].substring(0, 5)
            : "00:00";

          return updateDatasetConfig({
            slicePreset: item,
            presetEnd: undefined,
            presetStart: undefined,
            presetStartTime,
            presetEndTime,
          });
        }}
        options={generateDefaultPresetsForSlice(
          dataSets.find((item) => item.value === datasetConfig.data_set),
        )}
      />
      <BearDatePicker />
    </>
  );
}

function parseIntEvent(onChange: (item: number) => void) {
  return function parseIntEventHelper(event: any) {
    try {
      return onChange(parseInt(event.target.value));
    } catch (e) {
      return onChange(0);
    }
  };
}

function parseFloatNumber(onChange: (item: number) => void) {
  return function parseFloatEventHelper(event: any) {
    try {
      return onChange(parseFloat(event.target.value));
    } catch (e) {
      return onChange(0.0);
    }
  };
}

function BearCheckbox({
  checked,
  onCheckedChange,
  name,
  required,
  label,
}: {
  checked: boolean;
  onCheckedChange: (item: boolean) => void;
  name: string;
  required?: boolean;
  label?: string;
}) {
  return (
    <div className="flex flex-col space-y-2">
      {label && <label htmlFor={name}>{label}</label>}
      <Checkbox checked={checked} onCheckedChange={onCheckedChange} />
    </div>
  );
}

function BearDatePicker() {
  const { data_set, presetStart, presetEnd, presetStartTime, presetEndTime } =
    useBearStore((state) => ({
      presetStart: state.datasetConfig.presetStart,
      presetEnd: state.datasetConfig.presetEnd,
      data_set: state.datasetConfig.data_set,
      presetStartTime: state.datasetConfig.presetStartTime,
      presetEndTime: state.datasetConfig.presetEndTime,
    }));
  const updateSlicePresetRange = useBearStore(
    (state) => state.updateSlicePresetRange,
  );
  const updateDatasetConfig = useBearStore(
    (state) => state.updateDatasetConfig,
  );

  const setBounds = dataSets.find((item) => item.value === data_set)?.bounds;
  const startBoundsYear = setBounds?.start
    ? moment.tz(setBounds.start, CommonDestinationTimezone).year()
    : 2000;
  const endBoundsYear = setBounds?.end
    ? moment.tz(setBounds.end, CommonDestinationTimezone).year()
    : moment().year();

  const fromDate = setBounds?.start
    ? moment(setBounds.start).toDate()
    : undefined;
  const toDate = setBounds?.end ? moment(setBounds.end).toDate() : undefined;

  const onChange = (range: DateRange | undefined) => {
    if (!range) return updateSlicePresetRange(undefined);

    let updateStart: any = !range.from
      ? { presetStartTime: "00:00" }
      : moment(fromDate).isAfter(range.from)
        ? {
            presetStart: fromDate,
            presetStartTime: moment(fromDate).format("HH:mm"),
          }
        : {
            presetStart: range.from,
            presetStartTime,
          };

    let updateEnd: any = !range.to
      ? { presetEndTime: "00:00" }
      : moment(toDate).isBefore(range.to)
        ? {
            presetEnd: toDate,
            presetEndTime: moment(toDate).format("HH:mm"),
          }
        : {
            presetEnd: range.to,
            presetEndTime,
          };

    return updateSlicePresetRange({
      ...updateStart,
      ...updateEnd,
      slicePreset: "",
    });
  };

  const onTimeChange = (type: "start" | "end") => (e: any) => {
    updateDatasetConfig({ slicePreset: "" });

    const time = e.target.value;

    if (type === "start") {
      return updateSlicePresetRange({
        presetStartTime: !time ? "00:00" : time,
      });
    } else {
      // TODO add proper reseting on range
      return updateSlicePresetRange({
        presetEndTime: !time ? "00:00" : time,
      });
    }
  };

  const boundStartMin =
    setBounds?.start && moment(setBounds.start).isSame(presetStart)
      ? moment(setBounds.start).format("HH:mm")
      : undefined;
  const boundEndMax =
    setBounds?.end && moment(setBounds.end).isSame(presetEnd)
      ? moment(setBounds.end).format("HH:mm")
      : undefined;

  return (
    <>
      <DateRangePicker
        className="col-span-2"
        start={presetStart}
        end={presetEnd}
        onChange={onChange}
        fromDate={fromDate}
        toDate={toDate}
        fromYear={startBoundsYear}
        toYear={endBoundsYear}
      />
      {!!presetStart && !!presetEnd && (
        <>
          <span>
            <Label className="" htmlFor="manualFromTime">
              From
            </Label>
            <Input
              type="time"
              id="manualFromTime"
              step="300"
              value={presetStartTime}
              onChange={onTimeChange("start")}
              min={boundStartMin}
            />
          </span>
          <span>
            <Label className="" htmlFor="manualToTime">
              To
            </Label>
            <Input
              type="time"
              id="manualToTime"
              step="300"
              value={presetEndTime}
              onChange={onTimeChange("end")}
              max={boundEndMax}
            />
          </span>
        </>
      )}
    </>
  );
}

function BearSelectInput({
  value,
  onValueChange,
  options,
  name,
  required,
  label,
}: {
  value: string;
  onValueChange: (item: string) => void;
  options: { value: string; label: string }[];
  name: string;
  required?: boolean;
  label?: string;
}) {
  return (
    <div className="flex flex-col space-y-2">
      {label && <label htmlFor={name}>{label}</label>}
      <SelectInput
        value={value}
        onValueChange={onValueChange}
        options={options}
      />
    </div>
  );
}

function BearIntInput({
  value,
  onChange,
  name,
  min,
  max,
  required,
  step,
  label,
}: {
  value: number;
  onChange: (item: number) => void;
  name: string;
  min?: string;
  max?: string;
  required?: boolean;
  step: string;
  label?: string;
}) {
  return (
    <div className="flex flex-col space-y-2">
      {label && <label htmlFor={name}>{label}</label>}
      <Input
        value={value}
        id={name}
        type="number"
        required
        min={min}
        max={max}
        step={step}
        onChange={parseIntEvent(onChange)}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        onFocus={handleFocus}
      />
    </div>
  );
}

function BearFloatInput({
  value,
  onChange,
  name,
  min,
  max,
  required,
  step,
  label,
}: {
  value: number;
  onChange: (item: number) => void;
  name: string;
  min?: string;
  max?: string;
  required?: boolean;
  step: string;
  label?: string;
}) {
  return (
    <div className="flex flex-col space-y-2">
      {label && <label htmlFor={name}>{label}</label>}
      <Input
        value={value}
        id={name}
        type="number"
        required
        min={min}
        max={max}
        step={step}
        onChange={parseFloatNumber(onChange)}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        onFocus={handleFocus}
      />
    </div>
  );
}

function calculateStopLossFields(values: SignalConfig) {
  let lossMultiplier =
    values.baseStopConfig.stopLossMode === StopTypeMode.Percentage ? 0.01 : 1;

  let modifier = undefined;
  switch (values.baseStopConfig.stopLossModifier.type) {
    case StopLossModifierType.None:
      modifier = StopLossModifierType.None;
      break;
    case StopLossModifierType.Fixed:
      modifier = {
        [StopLossModifierType.Fixed]: {
          new_amount:
            values.baseStopConfig.stopLossModifier.fixed.new_amount *
            lossMultiplier,
        },
      };
      break;
    case StopLossModifierType.Linear:
      modifier = {
        [StopLossModifierType.Linear]: {
          amount:
            values.baseStopConfig.stopLossModifier.linear.amount *
            lossMultiplier,
          min:
            values.baseStopConfig.stopLossModifier.linear.min * lossMultiplier,
        },
      };
      break;
    default:
      modifier = StopLossModifierType.None;
      break;
  }

  const StopGainPercent =
    values.baseStopConfig.stopGainMode === StopTypeMode.Percentage
      ? values.baseStopConfig.gainData.percentAmount * 0.01
      : values.baseStopConfig.gainData.fixedAmount;
  const StopLossPercent =
    values.baseStopConfig.stopLossMode === StopTypeMode.Percentage
      ? values.baseStopConfig.lossData.percentAmount * 0.01
      : values.baseStopConfig.lossData.fixedAmount;

  return {
    StopLossMode: values.baseStopConfig.stopLossMode,
    StopGainMode: values.baseStopConfig.stopGainMode,
    StopGainPercent,
    StopLossPercent,
    StopLossModifier: modifier,
  };
}

function calculateDataSet(values: BearState) {
  if (values.datasetConfig.data_set.startsWith("one-minute")) {
    return {
      OneMinute: values.datasetConfig.data_set,
    };
  } else if (values.datasetConfig.data_set.startsWith("five-minute")) {
    return {
      FiveMinute: values.datasetConfig.data_set,
    };
  }

  return {
    Day: values.datasetConfig.data_set,
  };
}

function convertSignalConfig(
  config: SignalConfig,
  refresh_sell_back_off_as_buy: boolean,
) {
  return {
    Id: config.id,
    SignalsRanges: Object.values(config.signals_ranges).filter(
      (item) => !!item,
    ),
    IncubationPeriod: config.standardConfig.incubationPeriod,
    SignalCountRequired: config.standardConfig.signalCountRequired,
    BuyBackoffPeriod: config.baseBackoffConfig.buy_backoff,
    SellBackoffPeriod: config.baseBackoffConfig.sell_backoff,
    ...calculateStopLossFields(config),
    // NOTE: This is set on the backend dynamically from the data_set chosen
    PurchaseSize: 1,
    ConvergencePeriod: 150,
    BackOffMode: config.standardConfig.backoffMode,
    RiskStopLossConfig: calculateRiskStopLossConfig(config.riskStoplossConfig),
    RiskDrawdownConfig: calculateRiskDrawdownConfig(config.riskDrawdownConfig),
    RiskIndexEventRulesConfig: calculateRiskDrawdownConfig(
      config.riskIndexEventRulesConfig,
    ),
    RiskOrderEventRulesConfig: calculateRiskDrawdownConfig(
      config.riskOrderEventRulesConfig,
    ),
    RefreshSellBackoffAsBuy:
      refresh_sell_back_off_as_buy == null
        ? true
        : refresh_sell_back_off_as_buy,
  };
}

function convertToApiBody(values: BearState) {
  const requestBody = {
    config: {
      signal_configs: !values.use_timer
        ? [
            convertSignalConfig(
              values.up_trend,
              values.baseState.refresh_sell_back_off_as_buy,
            ),
          ]
        : [
            convertSignalConfig(
              values.up_trend,
              values.baseState.refresh_sell_back_off_as_buy,
            ),
            convertSignalConfig(
              values.down_trend,
              values.baseState.refresh_sell_back_off_as_buy,
            ),
          ],
      timer: values.use_timer ? values.timer : null,
      default_config_id: values.baseState.default_config_id,
      TickCostInAsset: 12.5,
      PointCostInAsset: 50,
      TickSize: 0.25,
      RiskEquityPerTrade: values.baseState.riskEquityPerTrade / 100,
      OutputIsMostRecentDateFirst: true,
    },
    write_periods_to_s3: values.baseState.write_periods_to_s3,
    graph_cumulative_gain: values.baseState.graph_cumulative_gain,
    private_key: values.private_key,
    data_set: calculateDataSet(values),
    data_set_slice: calculateDataSetSlice(values.datasetConfig),
  };
  return requestBody;
}

function convert_api_signal_config_to_bear_config(api_signal_config: any) {
  const stopLossModifierType =
    api_signal_config.StopLossModifier === StopLossModifierType.None
      ? StopLossModifierType.None
      : !!api_signal_config.StopLossModifier[StopLossModifierType.Fixed]
        ? StopLossModifierType.Fixed
        : StopLossModifierType.Linear;
  let lossMultiplier =
    api_signal_config.StopLossMode === StopTypeMode.Percentage ? 100 : 1;
  return {
    id: api_signal_config.Id,
    signals_ranges: api_signal_config.SignalsRanges.reduce(
      (acc: any, range: any) => {
        acc[range.Type] = range;
        return acc;
      },
      {} as any,
    ),
    standardConfig: {
      incubationPeriod: api_signal_config.IncubationPeriod,
      signalCountRequired: api_signal_config.SignalCountRequired,

      purchaseSize: api_signal_config.PurchaseSize,
      convergencePeriod: api_signal_config.ConvergencePeriod,

      backoffMode: api_signal_config.BackOffMode,
    },
    baseStopConfig: {
      stopGainMode: api_signal_config.StopGainMode,
      stopLossMode: api_signal_config.StopLossMode,
      lossData: {
        fixedAmount:
          api_signal_config.StopLossMode === StopTypeMode.Percentage
            ? 110
            : api_signal_config.StopLossPercent,
        percentAmount:
          api_signal_config.StopLossMode === StopTypeMode.Percentage
            ? api_signal_config.StopLossPercent * 100
            : 1,
      },
      gainData: {
        fixedAmount:
          api_signal_config.StopGainMode === StopTypeMode.Percentage
            ? 110
            : api_signal_config.StopGainPercent,
        percentAmount:
          api_signal_config.StopGainMode === StopTypeMode.Percentage
            ? api_signal_config.StopGainPercent * 100
            : 6,
      },
      stopLossModifier: {
        type: stopLossModifierType,
        fixed:
          stopLossModifierType === StopLossModifierType.Fixed
            ? {
                new_amount:
                  api_signal_config.StopLossModifier[StopLossModifierType.Fixed]
                    .new_amount * lossMultiplier,
              }
            : { new_amount: 0.5 },
        linear:
          stopLossModifierType === StopLossModifierType.Linear
            ? {
                amount:
                  api_signal_config.StopLossModifier[
                    StopLossModifierType.Linear
                  ].amount * lossMultiplier,
                min:
                  api_signal_config.StopLossModifier[
                    StopLossModifierType.Linear
                  ].min * lossMultiplier,
              }
            : { amount: 0.3, min: 0.5 },
      },
    },
    baseBackoffConfig: {
      buy_backoff: api_signal_config.BuyBackoffPeriod,
      sell_backoff: api_signal_config.SellBackoffPeriod,
    },

    riskIndexEventRulesConfig: {
      enabled: !!api_signal_config.RiskIndexEventRulesConfig,
      data: !!api_signal_config.RiskIndexEventRulesConfig
        ? calculateBearDrawdownConfigFromApi(
            api_signal_config.RiskIndexEventRulesConfig,
            {
              fixed: 120,
              points: 30,
            },
          )
        : {
            target_decline: { fixedAmount: 120, pointsAmount: 30 },
            target_decline_mode: DrawdownTypeMode.Points,
            target_timeframe: 5,
            result_block_bars: 5,
          },
    },
    riskOrderEventRulesConfig: {
      enabled: !!api_signal_config.RiskOrderEventRulesConfig,
      data: !!api_signal_config.RiskOrderEventRulesConfig
        ? calculateBearDrawdownConfigFromApi(
            api_signal_config.RiskOrderEventRulesConfig,
            {
              fixed: 120,
              points: 30,
            },
          )
        : {
            target_decline: { fixedAmount: 120, pointsAmount: 30 },
            target_decline_mode: DrawdownTypeMode.Points,
            target_timeframe: 5,
            result_block_bars: 5,
          },
    },
    riskDrawdownConfig: {
      enabled: !!api_signal_config.RiskDrawdownConfig,
      data: !!api_signal_config.RiskDrawdownConfig
        ? calculateBearDrawdownConfigFromApi(
            api_signal_config.RiskDrawdownConfig,
            {
              fixed: 120,
              points: 30,
            },
          )
        : {
            target_decline: { fixedAmount: 800, pointsAmount: 400 },
            target_decline_mode: DrawdownTypeMode.Points,
            target_timeframe: 100,
            result_block_bars: 80,
          },
    },
    riskStoplossConfig: {
      enabled: !!api_signal_config.RiskStopLossConfig,
      data: !!api_signal_config.RiskStopLossConfig
        ? {
            target_count: api_signal_config.RiskStopLossConfig.target_count,
            result_block_bars:
              api_signal_config.RiskStopLossConfig.result_block_bars,
            target_timeframe:
              api_signal_config.RiskStopLossConfig.target_timeframe,
            target_loss_pattern: {
              type:
                typeof api_signal_config.RiskStopLossConfig
                  .target_loss_pattern === "object"
                  ? "any"
                  : "same",
              same: {
                backoff_type:
                  typeof api_signal_config.RiskStopLossConfig
                    .target_loss_pattern === "object"
                    ? api_signal_config.RiskStopLossConfig.target_loss_pattern
                        .same
                    : "Sell",
              },
            },
          }
        : {
            target_count: 12,
            result_block_bars: 60,
            target_timeframe: 50,
            target_loss_pattern: {
              type: "same",
              same: { backoff_type: "Sell" },
            },
          },
    },
  };
}

function convertApiBodyToBearConfig(parentConfig: any) {
  const presetSlice = parentConfig.data_set_slice;
  const ats_config = parentConfig.config;

  const up = ats_config.signal_configs.find(
    (config: any) => config.Id === "up_trend",
  )!;
  const down = ats_config.signal_configs.find(
    (config: any) => config.Id === "down_trend",
  );

  return {
    up_trend: {
      ...convert_api_signal_config_to_bear_config(up),
      id: "up_trend",
      refresh_sell_back_off_as_buy:
        ats_config.signal_configs[0].RefreshSellBackoffAsBuy,
    },
    down_trend: !!down
      ? convert_api_signal_config_to_bear_config(down)
      : {
          ...generateBaseSignalConfig(),
          id: "down_trend",
          refresh_sell_back_off_as_buy:
            ats_config.signal_configs[0].RefreshSellBackoffAsBuy,
        },
    timer: !!down
      ? ats_config.timer
      : {
          bar_size: "1d",
          down_trend_config_id: "down_trend",
          up_trend_config_id: "up_trend",
          marker_end: "15:55",
          study_type: RangeSignalType.SMA,
          trend_threshold: 100,
          window_size: 9,
        },
    use_timer: !!down ? true : false,
    datasetConfig: {
      data_set: Object.values(parentConfig.data_set || {})[0],
      presetStart: presetSlice
        ? moment
            .tz(
              presetSlice.start,
              "YYYY-MM-DDTHH:mm:ss",
              CommonDestinationTimezone,
            )
            .toDate()
        : undefined,
      presetEnd: presetSlice
        ? moment
            .tz(
              presetSlice.end,
              "YYYY-MM-DDTHH:mm:ss",
              CommonDestinationTimezone,
            )
            .toDate()
        : undefined,
      presetStartTime: presetSlice
        ? moment
            .tz(
              presetSlice.start,
              "YYYY-MM-DDTHH:mm:ss",
              CommonDestinationTimezone,
            )
            .format("HH:mm")
        : "00:00",
      presetEndTime: presetSlice
        ? moment
            .tz(
              presetSlice.end,
              "YYYY-MM-DDTHH:mm:ss",
              CommonDestinationTimezone,
            )
            .format("HH:mm")
        : "00:00",
      slicePreset: "",
    },
    baseState: {
      tickSize: ats_config.TickSize,
      riskEquityPerTrade: ats_config.RiskEquityPerTrade * 100.0,
      TickCostInAsset: ats_config.TickCostInAsset,
      PointCostInAsset: ats_config.PointCostInAsset,
      outputIsMostRecentDateFirst: ats_config.OutputIsMostRecentDateFirst,
      graph_cumulative_gain: false,
      write_periods_to_s3: false,
      default_config_id: ats_config.default_config_id,
      refresh_sell_back_off_as_buy:
        ats_config.signal_configs[0].RefreshSellBackoffAsBuy,
    },
    private_key: "",
  };
}

function calculateDataSetSlice({
  slicePreset,
  presetEnd,
  presetStart,
  presetStartTime,
  presetEndTime,
}: DatasetConfig) {
  if (!slicePreset && !presetStart) return undefined;
  if (slicePreset) return calculateDataSetPreslice(slicePreset);
  return calculateDataSetBounds({
    presetStart,
    presetEnd,
    presetStartTime,
    presetEndTime,
  });
}

function calculateDataSetPreslice(slicePreset: string) {
  const a = slicePreset.split("/");
  if (!a.length || a.length !== 2) return undefined;

  return {
    start: a[0],
    end: a[1],
  };
}

function calculateDataSetBounds({
  presetStart,
  presetEnd,
  presetStartTime,
  presetEndTime,
}: {
  presetStart?: Date;
  presetEnd?: Date;
  presetStartTime: string;
  presetEndTime: string;
}) {
  if (!presetStart || !presetEnd) return undefined;

  const start = `${moment(presetStart).format("YYYY-MM-DD")}T${presetStartTime}:00`;
  const end = `${moment(presetEnd).format("YYYY-MM-DD")}T${presetEndTime}:00`;

  return {
    start,
    end,
  };
}

function calculateRiskStopLossConfig(
  enabledConfig: SignalConfig["riskStoplossConfig"],
) {
  if (!enabledConfig.enabled) return null;
  const config = enabledConfig.data;

  const target_loss_pattern =
    config.target_loss_pattern.type === "any"
      ? "any"
      : { same: config.target_loss_pattern.same.backoff_type };

  return {
    target_count: config.target_count,
    target_loss_pattern: target_loss_pattern,
    target_timeframe: config.target_timeframe,
    result_block_bars: config.result_block_bars,
  };
}

function calculateRiskDrawdownConfig(
  enabledConfig: SignalConfig["riskDrawdownConfig"],
) {
  if (!enabledConfig.enabled) return null;
  const config = enabledConfig.data;

  const target_decline =
    config.target_decline_mode === DrawdownTypeMode.Points
      ? config.target_decline.pointsAmount
      : config.target_decline.fixedAmount;
  return {
    target_decline,
    target_decline_mode: config.target_decline_mode,
    target_timeframe: config.target_timeframe,
    result_block_bars: config.result_block_bars,
  };
}

function calculateBearDrawdownConfigFromApi(
  apiSignalConfig: any,
  defaultDecline: { fixed: number; points: number },
) {
  const target_decline = {
    fixedAmount:
      apiSignalConfig.target_decline_mode !== DrawdownTypeMode.Points
        ? apiSignalConfig.target_decline
        : defaultDecline.fixed,
    pointsAmount:
      apiSignalConfig.target_decline_mode === DrawdownTypeMode.Points
        ? apiSignalConfig.target_decline
        : defaultDecline.points,
  };
  return {
    target_decline,
    target_decline_mode: apiSignalConfig.target_decline_mode,
    target_timeframe: apiSignalConfig.target_timeframe,
    result_block_bars: apiSignalConfig.result_block_bars,
  };
}
