import { create } from "zustand";
import { useState } from "react";
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone, createDateLabel } from "../../lib/utils";
import {
  CommodityOptions,
  Commodities,
} from "../../lib/api/commodity";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import { processFromFiles } from "../../lib/arReport";
import { reduceArrayTo, formulateOptionMonths } from "../../lib/api/common";
import { useListTraders } from "../../lib/api/trader";
import { useListCustomers } from "../../lib/api/customer";
import { Trader, Customer } from "../../lib/api/reports";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import { FormGridHelper } from "./helper";
import {
  DataTable,
  DataTableProps,
} from "../../components/ui/virtualizedTable";
import { safeParseDecimal } from "../../lib/excel";

export const ArReportFormSchema = {
    file_trial_balance: { label: "Trial Balance", fileExtensions: [".xlsx"], input_type: InputType.File, input_type_validation: InputFormType.File, required_status: InputRequiredStatus.Required },
    file_unsettled_ar_us: { label: "Unsettled AR US$", fileExtensions: [".xlsx"], input_type: InputType.File, input_type_validation: InputFormType.File, required_status: InputRequiredStatus.Required },
    file_unsettled_ar_can: { label: "Unsettled AR CAN$", fileExtensions: [".xlsx"], input_type: InputType.File, input_type_validation: InputFormType.File, required_status: InputRequiredStatus.Required },
    fx_rate: { label: "FX Rate", input_type: InputType.Decimal, input_type_validation: InputFormType.Decimal, required_status: InputRequiredStatus.Required },
    agg_non_comm_limit: { label: "Non Commercial Limit", input_type: InputType.Decimal, input_type_validation: InputFormType.Decimal, required_status: InputRequiredStatus.Required },
    agg_comm_limit: { label: "Commercial Limit", input_type: InputType.Decimal, input_type_validation: InputFormType.Decimal, required_status: InputRequiredStatus.Required },
};

const downloadReportState = FormHelper.generateBaseState({
    schemas: [ArReportFormSchema],
    baseSchema: ArReportFormSchema,
    overrideValues: { agg_non_comm_limit: 750000, agg_comm_limit: 6000000, fx_rate: 1 }
});

export function ArReportDashboard() {
  const { toast } = useToast();
  const [errors, setErrors] = useState<undefined | any>(undefined);
  const inputState: any = downloadReportState.useInputsState((state: any) => state);
  const submissionState: any = downloadReportState.useSubmissionState((state: any) => state);
  const customersResult = useListCustomers();
  const loadingOrErrorUi = GenerateLoadingOrError([customersResult]);

    if (loadingOrErrorUi) {
            return (
                <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl mb-4">
                    AR Report
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

    const customers = customersResult.data?.data || [];

    const onSubmitTo = async (item: any) => {
        const result = await processFromFiles({ files: {
            file_trial_balance: item.file_trial_balance,
            file_unsettled_ar_us: item.file_unsettled_ar_us,
            file_unsettled_ar_can: item.file_unsettled_ar_can,
        } as any }, { customers }, {
            manualFxRate: safeParseDecimal(item.fx_rate).toNumber(),
            aggNonCommLimit: safeParseDecimal(item.agg_non_comm_limit).toNumber(),
            aggCommLimit: safeParseDecimal(item.agg_comm_limit).toNumber(),
        });
        if (result.success) {
            setErrors(result.data.errors);
        }

        return result;
    };

    return (
        <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl mb-4">
                AR Report
            </h1>
            <div className="flex flex-col justify-end gap-4 mb-4">
                <FormGridHelper
                    onSuccess={() => {}}
                    state={downloadReportState}
                    activeSchema={ArReportFormSchema}
                    id={"DownloadArGeneralContract"}
                    externalReferences={{}}
                    failMessage="Failed to generate AR Report"
                    successMessage="Generated"
                    onSubmitTo={onSubmitTo}
                    onCloseForm={() => {}}
                    preventSubmitClose={true}
                />
                {errors && (
                    <ReportErrors errorsDict={errors}/>
                )}
            </div>
        </section>
    );
}

export function ReportErrors({ errorsDict }: any) {
    const values = Object.entries(errorsDict || {}).reduce((acc, [key, value]: [string, any]) => {
        const sectionType = key === 'unsettledUs' ? "Unsettled US" : key === 'unsettledCan' ? "Unsettled Can": "Trial Balance";
        Object.entries(value || {}).forEach(([key, value]: [string, any]) => {
            const errorType = key === 'missingCustomer' ? "Missing Customer" : key === 'missingCurrency' ? "Missing Currency": key === 'unknownCustomer' ? "Unknown Customer" : "Unknown Currency";
            Object.keys(value || {}).forEach((item: string) => {
                acc.push({
                    sectionType,
                    errorType,
                    item,
                });
            });
        });

        return acc;
    }, [] as any[]);

    const pairedColumns = [{
        id: "section_type",
        header: "File",
        cell: ({row}: any) => row.original.sectionType,
    }, {
        id: "error_type",
        header: "Error",
        cell: ({row}: any) => row.original.errorType,
    }, {
        id: "info_type",
        header: "Info",
        cell: ({row}: any) => row.original.item,
    }];

    return (
        <>
        <h2 className="scroll-m-20 text-xl font-bold tracking-tight mb-4">
            Errors
        </h2>
        <DataTable
            className="max-h-[16rem] md:max-h-[24rem] lg:max-h-[30rem] grid overflow-y-auto overflow-x-auto"
            height={"500px"}
            onRowClick={(item) => {}}
            columns={pairedColumns}
            data={values}
        />
        </>
    )
}
