import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import moment from "moment-timezone";
import { Label } from "../../components/ui/label";
import { Loader } from "../../components/ui/loader";
import { Input } from "../../components/ui/input";
import {
  TicketRunSummary,
  TicketsSummary,
  Provider,
} from "../../lib/models/index";
import { useGetTraderCustomersInfo } from "../../lib/api/reports";
import { processFromFiles } from "../../lib/spreadReport";
import {
  TicketProviderSummary,
  TicketProviderRecentRunPartial,
  TicketProviderBatchDownloadPartial,
} from "../../lib/models/tickets";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Skeleton } from "../../components/ui/skeleton";
import { useToast } from "../../components/ui/use-toast";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { cn } from "../../lib/utils";

export function SpreadReport() {
  return <ReportView />;
}

function ReportView({}: {}) {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [purchaseReport, setPurchaseReport] = useState<File | undefined>(undefined);
  const [salesReport, setSalesReport] = useState<File | undefined>(undefined);
  const [marketZoneReport, setMarketZoneReport] = useState<File | undefined>(undefined);
  const [openPositionReport, setOpenPositionReport] = useState<File | undefined>(undefined);

  const onUploadFile = async () => {
    if (isSubmitting) return;
    if (!purchaseReport || !salesReport || !marketZoneReport || !openPositionReport) return;

    setIsSubmitting(true);

    try {

        // TODO surface errors
      await processFromFiles({ files: {
        purchaseRelease: purchaseReport,
        salesRelease: salesReport,
        byMarketZone: marketZoneReport,
        openPositionsFuturesAndOptions: openPositionReport,
      }});

    } catch (error: any) {
      console.error("Failed to generate spread report file", error);
        toast({
            title: "Failed to create new spread report file.",
            description: "",
        });
    }

    setIsSubmitting(false);
  };

  const onPurchaseReportChange = (item: any) => {
    setPurchaseReport(item.target.files[0]);
  };
  const onSalesReporthange = (item: any) => {
    setSalesReport(item.target.files[0]);
  };
  const onMarketZoneChange = (item: any) => {
    setMarketZoneReport(item.target.files[0]);
  };
  const onOpenPositionChange = (item: any) => {
    setOpenPositionReport(item.target.files[0]);
  };

  return (
      <>
          <div className="container flex flex-col mb-2">
              <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                  Spread Report
              </h1>
          </div>
          <div className="flex items-start flex-col h-full mt-4">
              <div className="container flex flex-col gap-4">
                  <div className="grid items-center gap-1.5">
                      <Label htmlFor="setPurchaseReport">Purchases Reports</Label>
                      <Input
                          id="setPurchaseReport"
                          type="file"
                          accept=".csv, .xlsx"
                          onChange={onPurchaseReportChange}
                      />
                  </div>
                  <div className="grid items-center gap-1.5">
                      <Label htmlFor="onSalesReporthange">Sales Report</Label>
                      <Input
                          id="onSalesReporthange"
                          type="file"
                          accept=".csv, .xlsx"
                          onChange={onSalesReporthange}
                      />
                  </div>
                  <div className="grid items-center gap-1.5">
                      <Label htmlFor="onMarketZoneChange">Market Zone Report</Label>
                      <Input
                          id="onMarketZoneChange"
                          type="file"
                          accept=".csv, .xlsx"
                          onChange={onMarketZoneChange}
                      />
                  </div>
                  <div className="grid items-center gap-1.5">
                      <Label htmlFor="onOpenPositionChange">Open Position Report</Label>
                      <Input
                          id="onOpenPositionChange"
                          type="file"
                          accept=".csv, .xlsx"
                          onChange={onOpenPositionChange}
                      />
                  </div>

                  <div className="grid w-full max-w-sm items-center gap-1.5 mt-2">
                      <Button
                          disabled={
                          isSubmitting ||
                          !purchaseReport ||
                          !salesReport ||
                          !marketZoneReport ||
                          !openPositionReport
                          }
                          id="uploadButton"
                          onClick={onUploadFile}
                      >
                          {isSubmitting ? <Loader /> : "Process"}
                      </Button>
                  </div>
              </div>
          </div>
      </>
  );
}
