import ExcelJS from "exceljs";
import Decimal from "decimal.js";
import moment from "moment-timezone";
import { ServerError, ValidationError } from "./responseResults";

// Define the border style
export const borderStyle = {
  style: 'thin',
  color: { argb: 'FF000000' } // Light black color
};

export const lightGreenFill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFE6FFE6' }  // Light green color
};

export enum CellFormat {
  NUMBER = "#,##0.00",
  ACCOUNTING = '_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)',
  NON_CURRENCY_ACCOUNTING = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)',
  Int = "#,##0",
  Float = "#,##0.00000",
  Float3 = "#,##0.000",
  MonthDate = "MM/DD/YYYY",
  Currency = "($1,234.10)",
  CurrencyRed = `$#,##0.00_);[Red]($#,##0.00)`,
}

export async function workbookToBuffer(
  workbook: ExcelJS.Workbook,
): Promise<Buffer> {
  return workbook.xlsx.writeBuffer() as any;
}

export function parseFromBrowserFile(
  file: File,
): Promise<{ success: boolean; data: string; error: any }> {
  return new Promise((res) => {
    var reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = function (event) {
      res({
        success: true,
        data: (event.target?.result as string) || "",
        error: null,
      });
    };
    reader.onerror = function (event) {
      res({ success: false, data: "", error: event });
    };
  });
}

function parseExcelFromBrowserFile(
  file: File,
): Promise<{ success: boolean; data?: ArrayBuffer; error: any }> {
  return new Promise((res) => {
    var reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function (event) {
      res({ success: true, data: event.target?.result as any, error: null });
    };
    reader.onerror = function (event) {
      res({ success: false, data: undefined, error: event });
    };
  });
}

export function safeParseDecimal(originalItem: string | number | Decimal, meta?: any): Decimal {
  const item = typeof originalItem === "string" ? originalItem?.trim()?.replace(/\$|,|\(|\)/img, "") : originalItem;

  try {
    if (item == null) { return new Decimal(0); }
    const decimal = new Decimal(item);
      if (decimal.isNaN()) {
          return new Decimal(0);
      }

      return decimal;
  } catch(error: any) {
    return new Decimal(0);
  }
}

export function setColumnInfo(sheet: ExcelJS.Worksheet, column: string | number, format: CellFormat, width = 8) {
    sheet.getColumn(column).numFmt = format;
    sheet.getColumn(column).width = width;
}


export function boldCell(sheet: ExcelJS.Worksheet, row: number, column: string | number) {
  const cell = sheet.getRow(row).getCell(column);
  if (cell.font) {
    cell.style = JSON.parse(JSON.stringify(cell.style));
    cell.font.bold = true;
  } else {
    cell.style.font = {
      ...JSON.parse(JSON.stringify(cell.style)),
      bold: true,
    };
  }
}

export async function addImage(imageBuffer: Buffer, { worksheet, extension, workbook, range }: { workbook: ExcelJS.Workbook, range: string; extension?: string; worksheet: any }) {
  try {
      const imageId = workbook.addImage({
        buffer: imageBuffer,
        extension: 'jpeg',
      });

      worksheet.addImage(imageId, range);
  } catch(error) {
    throw new ServerError("Failed to add image", {})
  }
}

// Function to merge border styles
export function mergeBorders(existingBorder: any, newBorder: any) {
  return {
    top: newBorder.top || existingBorder.top,
    left: newBorder.left || existingBorder.left,
    bottom: newBorder.bottom || existingBorder.bottom,
    right: newBorder.right || existingBorder.right
  };
}

// Apply borders while preserving existing ones
export function applyBorderToRange(worksheet: any, startCell: any, endCell: any, borderSides: any) {
  for (let col = startCell.col; col <= endCell.col; col++) {
    for (let row = startCell.row; row <= endCell.row; row++) {
      const cell = worksheet.getCell(row, col);
      const newBorder: any = {};
      for (const side of borderSides) {
        newBorder[side] = borderStyle;
      }
      cell.border = mergeBorders(cell.border || {}, newBorder);
    }
  }
}

export function setFontColor(worksheet: any, cellRefs: string[], color: any) {
  const cells = getCells(worksheet, cellRefs);
  cells.forEach((cell: any) => {
    cell.font = cell.font || {};
    cell.font.color = { argb: color };
  });
}

export function setFontSize(worksheet: any, cellRefs: string[], size: any) {
  const cells = getCells(worksheet, cellRefs);
  cells.forEach((cell: any) => {
    cell.font = cell.font || {};
    cell.font.size = size;
  });
}

export function setCellFill(worksheet: any, cellRefs: string[], color: any) {
  const cells = getCells(worksheet, cellRefs);
  cells.forEach((cell: any) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color }
    };
  });
}

export function setCellBorders(worksheet: any, cellRefs: string[], style = 'thin', color = '000000') {
  const cells = getCells(worksheet, cellRefs);
  const border = { style, color: { argb: color } };
  cells.forEach((cell: any) => {
    cell.border = {
      top: border,
      left: border,
      bottom: border,
      right: border
    };
  });
}

// Set cell fill
export function setCellAlignments(worksheet: any, cellRefs: string[], alignments = { vertical: 'middle', horizontal: 'center' }) {
  const cells = getCells(worksheet, cellRefs);
  cells.forEach((cell: any) => {
    cell.alignment = alignments;
  });
}

// Helper function to get cell objects from references
export function getCells(worksheet: any, cellRefs: string[]) {
  return cellRefs.map(ref => worksheet.getCell(ref));
}

// Helper function to get cell objects from references
export function getCell(worksheet: any, cellRef: string) {
  return worksheet.getCell(cellRef);
}


export function makePaperLikeSpreadsheet(worksheet: any) {
    // Hide gridlines
    worksheet.views = [
      { showGridLines: false }
    ];

    // Set white background for the entire sheet
    worksheet.background = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFFFF' }
    };

    // Remove all borders from cells
    worksheet.eachRow((row: any) => {
      row.eachCell((cell: any) => {
        cell.border = {
          top: { style: 'none' },
          left: { style: 'none' },
          bottom: { style: 'none' },
          right: { style: 'none' }
        };
      });
    });

    // Optionally, set default font to a common typewriter or manuscript font
    worksheet.eachRow((row: any) => {
      row.font = {
        name: 'Calibri',
        size: 12
      };
    });

    // Set print area to fit content
    worksheet.pageSetup.fitToPage = true;
    worksheet.pageSetup.fitToWidth = 1;
    worksheet.pageSetup.fitToHeight = 0;
}

export function parseExcelDateToTimezone(item: Date, timezone: string) {
  return moment.tz(moment.utc(item.toISOString()).format("YYYY/MM/DD"),"YYYY/MM/DD", timezone);
}
