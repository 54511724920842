import { create } from "zustand";
import { useState } from "react";
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone, createDateLabel } from "../../lib/utils";
import {
    Archive as ArchiveXIcon
} from "lucide-react";
import {
  CommodityOptions,
  Commodities,
} from "../../lib/api/commodity";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import {
    Uom,
    Currency,
    CostCategory,
    CalculateOn,
    AddDedAdjType,
    RateType,
    ContractType,
    PurchaseSaleType,
    useListGeneralContracts,
    useListLocations,
    useListMarketZones,
    GeneralContract,
    useCreateGeneralContract,
    useUpdateGeneralContract,
    Location, MarketZone,
    purchaseSaleOptions,
    uomOptions,
    currencyOptions,
    generateContractTypesWith,
    calculateOnOptions,
    rateTypeOptions,
    costCategoryOptions,
    addDedADjOptions,
    deliveryTermsOptions,
    DeliveryTerms,
    useDownloadOpenRecentContractData,
    useDownloadOpenImportContractData,
    useArchiveGeneralContract,
} from "../../lib/api/generalContract";
import { useListTraders } from "../../lib/api/trader";
import { reduceArrayTo, formulateOptionMonths } from "../../lib/api/common";
import { useListCustomers } from "../../lib/api/customer";
import { Trader, Customer } from "../../lib/api/reports";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import { FormGridHelper } from "../reports/helper";
import { BaseGeneralFormSchema, ContractAddDedAdjBaseForm, ContractMzAdjBaseForm } from "../../lib/schemas/generalContract";

export const DownloadRecentFormSchema = {
    created_date: { label: "Created Date", input_type: InputType.Date, input_type_validation: InputFormType.Date, required_status: InputRequiredStatus.Required },
};

export const BaseFormSchema = {
    archive_contract_button: { label: "Archive", action_reference_id: "onArchive", action: "button", derived: true, skipForm: true, apiDisplayFormat: ({ original }: any) => {
        return (
            <ArchiveXIcon className="h-4 w-4" />
        );
    } },
    ...BaseGeneralFormSchema,
}

const createState = FormHelper.generateBaseState({
    schemas: [BaseFormSchema],
    baseSchema: BaseFormSchema,
});

const updateState = FormHelper.generateBaseState({
    schemas: [BaseFormSchema],
    baseSchema: BaseFormSchema,
});

const downloadRecentState = FormHelper.generateBaseState({
    schemas: [DownloadRecentFormSchema],
    baseSchema: DownloadRecentFormSchema,
});

const downloadImportState = FormHelper.generateBaseState({
    schemas: [DownloadRecentFormSchema],
    baseSchema: DownloadRecentFormSchema,
});

function DownloadImportDynamicForm({ onCloseForm, onSubmitForm, onSuccess }: { preventSubmitClose?: boolean; onCloseForm: any; onSubmitForm: any; onSuccess: any })  {
    return (
        <FormGridHelper
        onSuccess={onSuccess}
            state={downloadImportState}
            activeSchema={DownloadRecentFormSchema}
            id={"DownloadImportGeneralContract"}
            externalReferences={{}}
            failMessage="Failed to generate import contract"
            successMessage="Generated"
            onSubmitTo={onSubmitForm}
            onCloseForm={onCloseForm}
            preventSubmitClose={true}
        />
    )
}

function DownloadRecentDynamicForm({ onCloseForm, onSubmitForm, onSuccess }: { preventSubmitClose?: boolean; onCloseForm: any; onSubmitForm: any; onSuccess: any })  {
    return (
        <FormGridHelper
        onSuccess={onSuccess}
        state={downloadRecentState}
        activeSchema={DownloadRecentFormSchema}
            id={"DownloadRecentGeneralContract"}
            externalReferences={{}}
            failMessage="Failed to generate recent contract"
            successMessage="Generated"
            onSubmitTo={onSubmitForm}
            onCloseForm={onCloseForm}
            preventSubmitClose={true}
        />
    )
}

function CreateDynamicForm({ onCloseForm, traders, customers, locations, marketZones }: { onCloseForm: any; locations: Location[]; marketZones: MarketZone[]; customers: Customer[]; traders: Trader[] }) {
    const createApiContract = useCreateGeneralContract();

    return (
        <FormGridHelper
            state={createState}
            activeSchema={BaseFormSchema}
            id={"CreateGeneralContract"}
            externalReferences={
                {
                    locations, marketZones,
                    marketZonesOptions: genIdOptionList(marketZones, "market_zone"),
                    locationOptions: genIdOptionList(locations, "long_name"),
                    customerOptions: genIdOptionList(customers, "long_name"),
                    commodityOptions: CommodityOptions,
                    purchaseSaleOptions,
                    uomOptions,
                    currencyOptions,
                    costCategoryOptions,
                    rateTypeOptions,
                    calculateOnOptions,
                    addDedADjOptions,
                    deliveryTermsOptions,
                    traderOptions: genIdOptionList(traders, "name"),
                    optionMonthOptions: formulateOptionMonths(),
                    contractTypeOptions: genFlatOptionList(generateContractTypesWith({})),
                }
            }
            failMessage="Failed to create contract"
            successMessage="Created"
            onSubmitTo={createApiContract}
            onCloseForm={onCloseForm}
            preventSubmitClose={false}
        />
    );
}

function UpdateDynamicForm({ onCloseForm, traders, customers, locations, marketZones }: { onCloseForm: any; locations: Location[]; marketZones: MarketZone[]; customers: Customer[]; traders: Trader[] }) {
    const updateApiContract = useUpdateGeneralContract();
    return (
        <FormGridHelper
            state={updateState}
            id={"UpdateGeneralContract"}
            activeSchema={BaseFormSchema}
            externalReferences={
            {
                locations, marketZones,
                marketZonesOptions: genIdOptionList(marketZones, "market_zone"),
                locationOptions: genIdOptionList(locations, "long_name"),
                customerOptions: genIdOptionList(customers, "long_name"),
                commodityOptions: CommodityOptions,
                purchaseSaleOptions,
                uomOptions,
                currencyOptions,
                costCategoryOptions,
                rateTypeOptions,
                addDedADjOptions,
                calculateOnOptions,
                deliveryTermsOptions,
                traderOptions: genIdOptionList(traders, "name"),
                optionMonthOptions: formulateOptionMonths(),
                contractTypeOptions: genFlatOptionList(generateContractTypesWith({})),
            }
            }
            failMessage="Failed to update contract"
            successMessage="Updated"
            onSubmitTo={updateApiContract}
            onCloseForm={onCloseForm}
            preventSubmitClose={false}
        />
    );
}


function CreateFormModal({ customers, traders, locations, marketZones }: { locations: Location[]; marketZones: MarketZone[]; customers: Customer[]; traders: Trader[] }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <PrimitiveDialogForm className="max-w-[98%] h-[98%]" isOpen={isOpen} onDialogChange={setIsOpen} buttonLabel={"Create Contract"} dialogTitle={"Create Contract"} form={<CreateDynamicForm onCloseForm={setIsOpen} locations={locations} customers={customers} marketZones={marketZones} traders={traders} />}/>
    );
}

function UpdateFormModal({ isOpen, onDialogChange, traders, customers, locations, marketZones }: { isOpen: boolean, onDialogChange: any; locations: Location[]; marketZones: MarketZone[]; customers: Customer[]; traders: Trader[] }) {
    return (
        <PrimitiveDialogForm className="max-w-[98%] h-[98%]" isOpen={isOpen} onDialogChange={onDialogChange} dialogTitle={"Update Contract"} form={<UpdateDynamicForm onCloseForm={onDialogChange} locations={locations} customers={customers} marketZones={marketZones} traders={traders} />}/>
    );
}

function DownloadImportReportsModal() {
    const [isOpen, setIsOpen] = useState(false);
    const [links, setLinks] = useState<any[]>([]);
    const onGenerateLinks = useDownloadOpenImportContractData();

    const onSuccess = (res: any) => {
        if (res.import_new_contracts_link) {
            setLinks([{ label: "Download Import", link: res.import_new_contracts_link }]);
        } else {
            setLinks([]);
        }
    };

    return (
        <PrimitiveDialogForm
            className=""
            isOpen={isOpen}
            onDialogChange={setIsOpen}
            buttonLabel="Download Import"
            dialogTitle={"Download Import For"}
            footer={(<span>
                       {links.map((wrapper) => {
                           return (
                               <a
                                   key={`DownloadImport:${wrapper.label}`}
                                   href={wrapper.link}
                                   target="_blank"
                                   className="underline underline-offset-1 decoration-sky-500"
                               >
                                       Download Report
                               </a>
                           )
                       })}
            </span>)}
            form={<DownloadImportDynamicForm onCloseForm={setIsOpen} onSubmitForm={onGenerateLinks} onSuccess={onSuccess}/>}
        />
    );
}

function DownloadRecentReportsModal() {
    const [isOpen, setIsOpen] = useState(false);
    const [links, setLinks] = useState<any[]>([]);
    const onGenerateLinks = useDownloadOpenRecentContractData();

    const onSuccess = (res: any) => {
        if (res.import_recent_link) {
            setLinks([{ label: "Download Recent", link: res.import_recent_link }]);
        } else {
            setLinks([]);
        }
    };

    return (
        <PrimitiveDialogForm
            className=""
            isOpen={isOpen}
            onDialogChange={setIsOpen}
            buttonLabel="Download Recent"
            dialogTitle={"Download Contracts For"}
            footer={(<span>
                       {links.map((wrapper) => {
                           return (
                               <a
                                   key={`DownloadRecent:${wrapper.label}`}
                                   href={wrapper.link}
                                   target="_blank"
                                   className="underline underline-offset-1 decoration-sky-500"
                               >
                                       Download Report
                               </a>
                           )
                       })}
            </span>)}
            form={<DownloadRecentDynamicForm onCloseForm={setIsOpen} onSubmitForm={onGenerateLinks} onSuccess={onSuccess}/>}
        />
    );
}

export function GeneralContractsDashboard() {
    const { toast } = useToast();
    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);
    const locationRes = useListLocations();
    const marketZoneRes = useListMarketZones();
    const customersResult = useListCustomers();
    const listTradersRes = useListTraders();
    const archiveGeneralContract = useArchiveGeneralContract();

    const contractsRes = useListGeneralContracts();
    const loadingOrErrorUi = GenerateLoadingOrError([locationRes, marketZoneRes, customersResult, contractsRes, listTradersRes]);
    const updatePopulateWith: any = updateState.useInputsState((state: any) => state.populateWith);
    const updateClearForm: any = updateState.useInputsState((state: any) => state.clearForm);

    if (loadingOrErrorUi) {
         return (
             <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    General Contracts
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

  const onArchive = async ({ id }: { id: string }) => {
    if (isArchiving) return;
    setIsArchiving(true);

    const result = await archiveGeneralContract({ id });
    setIsArchiving(false);

    if (!result.success) {
      toast({
        title: "Failed to archive contract",
        description: "",
      });
    } else {
        toast({
            title: "Archived",
            description: "",
        });
    }
  };

    const locations = locationRes.data?.data || [];
    const marketZones = marketZoneRes.data?.data || [];
    const customers = customersResult.data?.data || [];
    const contracts = contractsRes.data?.data || [];
    const traders = listTradersRes.data?.data || [];

    return (
        <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                General Contracts
            </h1>
            <div className="flex justify-end gap-4 mb-4">
                <DownloadImportReportsModal />
                    <DownloadRecentReportsModal />
                    <CreateFormModal locations={locations} marketZones={marketZones} customers={customers} traders={traders} />
                    <UpdateFormModal
                        traders={traders}
                        locations={locations}
                        marketZones={marketZones}
                        customers={customers}
                        isOpen={isUpdateOpen}
                        onDialogChange={
                        (value: any) => {
                            if (!value) {
                                setIsUpdateOpen(false);
                                updateClearForm();
                            }
                        }
                        }
                    />
            </div>
            <PrimitiveManagedTable
                externalReferences={{
                    onArchive: onArchive,
                    onArchiveLoading: isArchiving,
                }}
                schema={BaseFormSchema} values={contracts} onRowClick={(item) => {
                    setIsUpdateOpen(true);
                    updatePopulateWith(item);
            }} />
        </section>
    );
}
