import { useQuery, useQueryClient, QueryKey } from "@tanstack/react-query";
import Decimal from "decimal.js";
import { BaseDbObject } from "../models/db";
import moment, { Moment } from "moment-timezone";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import {
  PossibleReturnType,
} from "../responseResults";
import { CommonDestinationTimezone } from "../utils";
import { formatDateWithoutTimezone } from "./date";
import { DbUser } from "../models/index";

export interface StagedAgtechRow {
    "Contract No": string;
}

export interface StagedAgtechData {
    staged_data: StagedAgtechRow[]
    contracts: any[]
}

export function useListOtcAgtechData() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["otcAgtechData"],
    queryFn: () =>
      fetchApiGet<StagedAgtechData>("otc/agtech", queryParams),
    retry: 1,
  });
}

export function useListGeneralContractAgtechData() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["generalContractAgtechData"],
    queryFn: () =>
      fetchApiGet<StagedAgtechData>("contract/agtech", queryParams),
    retry: 1,
  });
}

export function useCreateAgtechUpload() {
  const queryClient = useQueryClient();

  return async (body: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `genericContract/agtech/upload/presign`,
      body,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["otcAgtechData"] });
    queryClient.invalidateQueries({ queryKey: ["generalContractAgtechData"] });

    return result;
  };
}

export function usePairStagedData() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `contract/agtech/${params.contract_id}/pair`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["generalContractAgtechData"] });
    return result;
  };
}

export function useMarkStagedData() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `contract/agtech/mark`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["generalContractAgtechData"] });
    return result;
  };
}
